@import '../../assets/sass/lbd/variables.scss';

.Toaster__alert {
  border: 1px solid $wedge-text-green;
  background: $wedge-light-green;
  color: $success-states-color;
  padding: 16px;
  padding-right: 72px;

  .Toaster__alert_text {
    color: $success-states-color;
    font-size: 16px;
    text-align: left;
  }
  .Toaster__alert_close {
    font-size: 24px;
    top: 3px;
    opacity: unset;
  }
}

@import '../../assets/sass/common.scss';
@import '../../assets/sass/lbd/variables.scss';
@import '../../assets/sass/lbd/mixins/vendor-prefixes';
@import '../../assets/sass/lbd/mixins.scss';

.customers-form-page {
  &--content {
    display: flex;
    position: relative;
    z-index: 4;
    padding-top: 100px;
    justify-content: center;

    @media only screen and (max-width: 992px) {
      padding-top: 32px;
    }

    .title {
      font-size: 26px;
      font-weight: 400;
      color: $wedge-primary-green;
      margin: 0 0 24px 0;
    }
    .customers-form {
      width: 420px;
      margin-bottom: 24px;

      @include respond-to($phone) {
        width: 100%;
        padding: 0 24px;
      }

      .customers-container {
        background-color: white;
        border: 1px #d0d4d6 solid;
        border-radius: 3px;
      }

      .divider {
        height: 1px;
        margin: 0 20px;
        background-color: #d0d4d6;
      }

      .single-customer {
        @extend .flex-row;
        justify-content: space-between;
        align-items: center;
        padding: 12px 20px;
        cursor: pointer;

        @include transition(0.3s, $transition-ease);

        &:hover {
          background-color: lighten(#d0d4d6, 10);
        }

        .customer-actions {
          display: flex;
          align-items: center;
          @media only screen and (max-width: 992px) {
            padding-left: 8px;
          }

          i {
            font-size: 24px;
            color: $wedge-secondary-gray;

            &:hover {
              color: $wedge-primary-gray;
            }
          }
          .forward-icon {
            width: 12px;
            height: 10px;
            margin-left: 15px;
          }
        }

        &-info {
          color: $wedge-blue-gray;
          overflow: hidden;
          word-break: break-all;
          margin-bottom: 0;
          &__name {
            font-size: 18px;
          }
          &__uuid {
            font-size: 10px;
          }
        }
      }
    }
  }

  &--footer {
    z-index: 1;
    position: fixed;
    bottom: 0;
    .footer--image {
      background-color: #f9f9f9;
      width: 100%;
      object-fit: contain;

      @media only screen and (max-width: 992px) {
        width: unset;
        max-height: 50vh;
      }
    }
  }
}

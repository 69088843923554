@import '../../assets/sass/common.scss';
@import '../../assets/sass/lbd/variables.scss';

.field {
  &__group {
    @extend .flex-column;
    justify-content: flex-start;
    margin-bottom: 24px;

    &-center {
      @extend .flex-column;
      justify-content: center;
    }
    &.self {
      width: unset;
      max-width: fit-content !important;
    }
    &.thing-uuid {
      @media only screen and (min-width: 992px) {
        width: 80%;
      }
    }
    .divider--small {
      width: 1px;
      background-color: #bfbfbf;
      opacity: 0.5;
      height: 16px;
      margin-left: 8px;
      margin-right: 8px;
    }
  }
  &__label {
    @extend .flex-row;
    align-items: center;
    font-size: 16px;
    font-weight: normal;
    color: $wedge-blue-gray;
    opacity: 1;

    .primary {
      opacity: 0.5;
    }

    .secondary {
      color: $wedge-primary-orange;
      opacity: 1;
    }
  }
  &__textfield {
    margin-top: 4px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    color: $wedge-light-gray;
    overflow: hidden;
    text-overflow: ellipsis;

    &.full {
      overflow: visible;
      text-overflow: unset;
    }
    &.primary {
      opacity: 0.5;
    }
  }
  &__copyfield {
    & .copy-button {
      background: none;
      border: 0.5px solid rgba(109, 137, 148, 0.5);
      border-radius: 3px;
      cursor: pointer;
      padding: 8px 8px 8px 10px;
      position: relative;
      width: fit-content;

      &:hover {
        box-shadow: 0px 5px 10px -2px rgba(136, 136, 136, 0.2);
        a {
          text-decoration: underline;
        }
      }
      &:focus {
        outline: none;
      }
      & .tool-tip__copied {
        position: absolute;
        right: 32px;
      }
      & .copy__textfield {
        border: none;
        color: #6d8994;
        display: inline;
        padding: 0;
        margin: 0;
        font-size: 14px;
        word-break: break-all;
      }
      & .copy__icon {
        color: #6d8994;
        margin-left: 5px;
        margin-right: 3px;
      }
    }
    &.smallScreen {
      & .copy-button {
        border: none;
        padding: 0px;
        margin: 0px;
        & .copy__textfield {
          font-size: 12px;
        }
      }
    }
  }
}

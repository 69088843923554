@import '../../assets/sass/lbd/variables.scss';
@import '../../assets/sass/common.scss';
@import '../../assets/sass/lbd/mixins/_vendor-prefixes.scss';

.organizations-wrapper.wrapper {
  background-image: url(../../assets/customer/extreme/login-bg.jpg);
  background-size: cover;

  .ecosystems__navbar {
    background-color: #f9f9f9;
  }

  .d-flex {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 4;
  }
  .button {
    background-color: $wedge-primary-blue;
    color: white;
    font-size: 18px;
    text-align: center;
    padding: 16px 24px;
    border-radius: 3.8px;
    cursor: pointer;
    @include transition(0.25s, null);
  }
  .customers--footer {
    @include transition(0.5s, $transition-ease);
    z-index: 1;
    position: fixed;
    bottom: 0;
    .footer--image {
      background-color: #f9f9f9;
      width: 100%;
      object-fit: contain;

      @media only screen and (max-width: 992px) {
        width: unset;
        max-height: 50vh;
      }
    }
  }
}

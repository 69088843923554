@import '../../assets/sass/lbd/variables';

.table {
  .header__wrapper {
    background-color: $wedge-primary-color;

		tr {
			height: 48px;
			th {
				padding: 0px;
				text-align: center;
				color: white;
				font-size: 14px;
				font-weight: 600;
				&.selection-cell-header input {
					margin: 0;
				}
				&:focus {
					outline: none;
				}
			}
		}
		.sortable img {
			margin: 0 6px;
			width: 12px;
			&.caret__asc {
				vertical-align: bottom;
			}
			&.caret__desc {
				vertical-align: top;
			}
		}
	}
	td {
		overflow-wrap: break-word;
		text-align: center;
		justify-content: center;
		.loader_ldap-status {
			display: flex;
			justify-content: center;
		}
	}
}

.react-bootstrap-table-pagination {
  margin-bottom: 24px;

  &-list {
    .pagination {
      display: flex;
      justify-content: flex-end;
      margin: 0;
    }
  }
}

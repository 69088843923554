@charset "UTF-8";
/*      light colors         */
.flex {
  display: flex; }

.flex-row, .centered-row, .modal__content .form-row, .modal__content .option-container, .modal__content.new-address-translation-survey .ecosystem--users_single,
.modal__content.new-address-translation-survey .policy--application_single,
.modal__content.new-address-translation-survey .policy--destination_single,
.modal__content.new-address-translation-survey .policy--sources_single,
.modal__content.new-address-translation-survey .policy--services_single, .modal__content.create-new-ecosystem .ecosystem--users_single, .modal__content.new-address-survey .type--thing_single, .modal__content.new-address-survey .objectinfo__type-container {
  display: flex;
  flex-direction: row; }

.flex-column, .modal__content.new-address-survey .objectinfo {
  display: flex;
  flex-direction: column; }

.flex-column-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

@media screen and (max-width: 768px) {
  .flex-column-container {
    display: flex;
    flex-direction: column; } }

.wrap {
  flex-wrap: wrap; }

.centered-row {
  align-items: center; }

.stretch {
  flex: 1;
  justify-content: space-between; }

.baseline {
  align-items: baseline; }

.center {
  align-items: center; }

.justify-center {
  justify-content: center; }

.justify-end {
  justify-content: flex-end; }

.justify-start {
  justify-content: flex-start; }

.right {
  margin-left: auto; }

.space-left {
  margin-left: 16px; }

.space-above {
  margin-top: 16px; }

.space-above-8 {
  margin-top: 8px; }

.space-above-16 {
  margin-top: 16px; }

.space-bottom {
  margin-bottom: 16px; }

.space-bottom-8 {
  margin-bottom: 8px; }

.capitalize {
  text-transform: capitalize; }

.component-coming-soon.hidden {
  visibility: collapse; }

@media only screen and (max-width: 992px) {
  .table-item:first-child {
    margin-top: 0px; } }

.table-item {
  background-color: white;
  border-radius: 4px;
  border: 0.5px solid #ebebeb;
  padding: 24px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  @media only screen and (max-width: 992px) {
    .table-item {
      flex-direction: column;
      justify-content: space-between;
      padding: 16px; } }

.coming-soon {
  display: flex;
  justify-content: center;
  min-height: 75vh; }
  .coming-soon img {
    width: 512px; }
    @media only screen and (max-width: 992px) {
      .coming-soon img {
        width: 320px; } }

.component-coming-soon {
  opacity: 0.33 !important; }
  .component-coming-soon * {
    pointer-events: none; }

@keyframes slideLeftAndRight {
  0% {
    transform: translateX(0); }
  25% {
    transform: translateX(-20px); }
  50% {
    transform: translateX(0); }
  75% {
    transform: translateX(20px); }
  100% {
    transform: translateX(0); } }

@keyframes flickerAnimation {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.25; }
  100% {
    opacity: 1; } }

.animate-flicker {
  animation-duration: 1.5s;
  animation-name: flickerAnimation;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: ease-in-out; }

.slow-shake {
  animation-duration: 1.5s;
  animation-name: slideLeftAndRight;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: ease-in-out; }

.popover {
  z-index: 1393 !important; }

.uppercase {
  text-transform: uppercase; }

.mb-0 {
  margin-bottom: 0; }

.mt-5 {
  margin-top: 5px; }

.mt-25 {
  margin-top: 25px; }

.pb-5 {
  padding-bottom: 5px; }

.pb-10 {
  padding-bottom: 10px; }

.radio {
  margin-bottom: 0px;
  margin-top: 0; }
  .radio label {
    margin: 5px;
    font-size: 15px; }
  .radio input {
    margin: 0 8px; }
    .radio input:checked + label::after {
      color: #6d8994; }

.wedge-sup {
  color: #00a550;
  padding-left: 4px; }

.noPadding {
  padding: 0; }

.pointer {
  cursor: pointer; }

.ldap-status-link {
  cursor: pointer;
  text-decoration: underline;
  border: none !important;
  outline: none !important;
  padding: 0; }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield; }

/*      light colors         */
@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg); } }

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg); }
  to {
    -ms-transform: rotate(360deg); } }

.modal__content {
  padding: 16px; }
  .modal__content.padded {
    padding: 24px 32px; }
  @media only screen and (max-width: 992px) {
    .modal__content {
      padding: 8px; }
      .modal__content.padded {
        padding: 16px; } }
  .modal__content .card .content {
    padding: 32px; }
    @media only screen and (max-width: 992px) {
      .modal__content .card .content {
        padding: 16px; } }
  .modal__content .form-row {
    justify-content: space-between;
    align-items: flex-start; }
    @media only screen and (max-width: 992px) {
      .modal__content .form-row {
        flex-direction: column;
        justify-content: center;
        align-items: stretch; } }
  .modal__content .form__group.full {
    max-width: 100% !important; }
  @media only screen and (min-width: 992px) {
    .modal__content .form__group {
      flex: 1 1 100%;
      max-width: 250px; } }
  @media only screen and (max-width: 992px) {
    .modal__content .form__group {
      max-width: 992px;
      margin-bottom: 8px; } }
  .modal__content p {
    color: #6d8994; }
    .modal__content p.big {
      font-size: 24px;
      font-weight: normal;
      margin-bottom: 0; }
    .modal__content p.error {
      background-color: rgba(255, 0, 0, 0.1);
      border-radius: 4px;
      color: rgba(255, 0, 0, 0.75);
      font-size: 14px;
      margin-bottom: 8px;
      padding: 8px 12px; }
    .modal__content p.error-text {
      font-size: 10px;
      color: red; }
    .modal__content p.medium {
      font-size: 12px; }
  .modal__content .option-container {
    align-items: center;
    cursor: pointer; }
    .modal__content .option-container p {
      margin-bottom: 0; }
    .modal__content .option-container .option {
      flex: 1;
      font-size: 20px;
      font-weight: normal;
      color: #6d8994; }
    .modal__content .option-container .type-icon--container {
      width: 48px; }
    .modal__content .option-container .type-icon {
      max-width: 30px;
      max-height: 24px;
      margin-right: 24px; }
    .modal__content .option-container .icon {
      max-height: 32px;
      color: #7f97a0; }
    .modal__content .option-container .go-arrow {
      height: 16px; }
  .modal__content .option-container:last-of-type {
    margin-bottom: 8px; }
  .modal__content .divider {
    background-color: #bfbfbf;
    opacity: 0.2; }
    .modal__content .divider-horizontal {
      margin-top: 24px;
      margin-bottom: 24px;
      height: 1px;
      width: 100%; }
  .modal__content .toggle-selected {
    color: #fff;
    background-color: #36cb98;
    border-color: #30b889; }
  .modal__content.privacy-policy {
    width: 95%;
    max-width: 95%;
    margin: 0 auto;
    padding: 15px 2%;
    color: #333333;
    font-size: 14px; }
    .modal__content.privacy-policy a {
      text-decoration: none;
      color: #612ba3;
      font-style: bold; }
    .modal__content.privacy-policy h2,
    .modal__content.privacy-policy h3,
    .modal__content.privacy-policy h4,
    .modal__content.privacy-policy h5,
    .modal__content.privacy-policy p {
      font-size: 14px;
      color: #333333; }
    .modal__content.privacy-policy h6 {
      margin-bottom: 5px;
      text-transform: none; }
    .modal__content.privacy-policy .italic {
      font-style: italic; }
    .modal__content.privacy-policy .encloser {
      height: 68vh;
      padding: 3% 3%;
      border-radius: 10px;
      border: 1px solid #d0d4d6;
      background: #ffffff; }
    .modal__content.privacy-policy .main.header {
      margin-bottom: 10px;
      margin-top: 0px; }
      .modal__content.privacy-policy .main.header h4 {
        font-size: 20px;
        font-weight: 600;
        margin-top: 5px;
        margin-bottom: 10px; }
    .modal__content.privacy-policy .main.content {
      height: 63.5vh;
      max-height: 63.5vh;
      width: auto;
      padding-right: 20px;
      overflow-y: auto;
      overflow-x: hidden;
      background: #ffffff; }
      .modal__content.privacy-policy .main.content .basic {
        margin-bottom: 30px; }
      .modal__content.privacy-policy .main.content::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #f5f5f5; }
      .modal__content.privacy-policy .main.content::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
        background-color: #f5f5f5; }
      .modal__content.privacy-policy .main.content::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #6d8994; }
    .modal__content.privacy-policy .content .black {
      color: #333333; }
    .modal__content.privacy-policy .content .head {
      font-weight: 600; }
    .modal__content.privacy-policy .content .section {
      padding-left: 25px;
      margin-bottom: 20px;
      position: relative; }
      .modal__content.privacy-policy .content .section::before {
        content: '•';
        position: absolute;
        left: 0px;
        top: -10px;
        font-size: 35px;
        color: #612ba3; }
      .modal__content.privacy-policy .content .section .header {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 20px;
        font-weight: 600; }
      .modal__content.privacy-policy .content .section .content {
        margin-top: 15px; }
    .modal__content.privacy-policy .content .menu {
      margin-bottom: 15px; }
      .modal__content.privacy-policy .content .menu .header {
        margin-top: 5px;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 18px; }
      .modal__content.privacy-policy .content .menu .content {
        margin-top: 5px; }
    .modal__content.privacy-policy .content .topic {
      margin-bottom: 10px; }
      .modal__content.privacy-policy .content .topic .header {
        margin-top: 15px;
        margin-bottom: 0px;
        font-weight: 600;
        font-size: 16px; }
      .modal__content.privacy-policy .content .topic .content ul.dashed {
        list-style: none;
        padding-left: 0;
        margin-top: 10px; }
        .modal__content.privacy-policy .content .topic .content ul.dashed li {
          position: relative;
          padding-left: 20px; }
          .modal__content.privacy-policy .content .topic .content ul.dashed li:before {
            content: '-';
            position: absolute;
            color: #612ba3;
            font-size: 30px;
            left: 0px;
            top: -14px; }
  .modal__content.new-address-translation-survey .form__group {
    flex: 1 0 100%; }
  .modal__content.new-address-translation-survey .full {
    justify-content: flex-start; }
    .modal__content.new-address-translation-survey .full:last-child {
      width: 100%; }
  .modal__content.new-address-translation-survey .card .content {
    padding: 32px; }
    @media only screen and (max-width: 992px) {
      .modal__content.new-address-translation-survey .card .content {
        padding: 12px; } }
  .modal__content.new-address-translation-survey .name-container {
    min-width: 200px; }
  @media only screen and (max-width: 992px) {
    .modal__content.new-address-translation-survey .icon-container {
      align-self: center; } }
  @media only screen and (max-width: 992px) {
    .modal__content.new-address-translation-survey .icon-container .middleIcon {
      justify-content: center;
      display: flex; } }
  .modal__content.new-address-translation-survey .icon-container .middleIcon .dividerImage {
    width: 45px;
    height: 45px;
    margin-top: 5px; }
  .modal__content.new-address-translation-survey .checkbox-label {
    margin-top: 16px;
    margin-bottom: 24px; }
    .modal__content.new-address-translation-survey .checkbox-label span.title {
      position: absolute;
      margin-left: 4px;
      font-size: 14px;
      font-weight: 600;
      color: #6d8994;
      text-transform: none;
      margin-top: 2px; }
  .modal__content.new-address-translation-survey .disabled.wedge-checkbox-container {
    opacity: 0.4;
    cursor: not-allowed; }
    .modal__content.new-address-translation-survey .disabled.wedge-checkbox-container:hover input ~ .checkmark {
      background-color: #eee; }
  .modal__content.new-address-translation-survey .notes-container {
    flex: 2; }
  .modal__content.new-address-translation-survey .expiry-container {
    flex: 1; }
  .modal__content.new-address-translation-survey .actions-container {
    flex: 1; }
  .modal__content.new-address-translation-survey .add-button {
    margin-right: 8px; }
  .modal__content.new-address-translation-survey .box {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .modal__content.new-address-translation-survey .box .section {
      margin-top: 20px;
      margin-bottom: 20px;
      flex: 1 1;
      justify-content: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-self: flex-start; }
      @media only screen and (max-width: 992px) {
        .modal__content.new-address-translation-survey .box .section {
          width: 100%;
          margin-top: 16px;
          margin-bottom: 0; } }
      .modal__content.new-address-translation-survey .box .section .mainHeader {
        color: #36cb98;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px; }
      .modal__content.new-address-translation-survey .box .section input {
        width: 100%; }
        .modal__content.new-address-translation-survey .box .section input::placeholder {
          color: #d3d7d8; }
    .modal__content.new-address-translation-survey .box .middleIcon {
      flex: 0.5 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media only screen and (max-width: 992px) {
        .modal__content.new-address-translation-survey .box .middleIcon {
          display: none; } }
      .modal__content.new-address-translation-survey .box .middleIcon p {
        margin-bottom: 10px; }
      .modal__content.new-address-translation-survey .box .middleIcon .dividerImage {
        align-self: center;
        width: 60px;
        height: 60px; }
  .modal__content.new-address-translation-survey .ecosystem--users_single,
  .modal__content.new-address-translation-survey .policy--application_single,
  .modal__content.new-address-translation-survey .policy--destination_single,
  .modal__content.new-address-translation-survey .policy--sources_single,
  .modal__content.new-address-translation-survey .policy--services_single {
    margin: 8px 0 0 0;
    justify-content: space-between; }
    .modal__content.new-address-translation-survey .ecosystem--users_single p,
    .modal__content.new-address-translation-survey .policy--application_single p,
    .modal__content.new-address-translation-survey .policy--destination_single p,
    .modal__content.new-address-translation-survey .policy--sources_single p,
    .modal__content.new-address-translation-survey .policy--services_single p {
      overflow: hidden;
      text-overflow: ellipsis; }
    .modal__content.new-address-translation-survey .ecosystem--users_single .red-delete-icon,
    .modal__content.new-address-translation-survey .policy--application_single .red-delete-icon,
    .modal__content.new-address-translation-survey .policy--destination_single .red-delete-icon,
    .modal__content.new-address-translation-survey .policy--sources_single .red-delete-icon,
    .modal__content.new-address-translation-survey .policy--services_single .red-delete-icon {
      font-size: 20px;
      margin-right: 8px;
      color: red;
      font-weight: bold;
      cursor: pointer; }
  .modal__content.new-address-translation-survey .action-index--image {
    width: 14px;
    margin-right: 5px; }
  .modal__content.new-address-translation-survey .form-row.boxContainer {
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center; }
    .modal__content.new-address-translation-survey .form-row.boxContainer .sectionContainer {
      width: 100%; }
      .modal__content.new-address-translation-survey .form-row.boxContainer .sectionContainer p.subHeader {
        margin-bottom: 15px;
        height: 40px; }
    .modal__content.new-address-translation-survey .form-row.boxContainer .form__group {
      max-width: 100%;
      margin-top: 10px; }
    .modal__content.new-address-translation-survey .form-row.boxContainer .sources-container .form__label,
    .modal__content.new-address-translation-survey .form-row.boxContainer .destinations-container .form__label,
    .modal__content.new-address-translation-survey .form-row.boxContainer .destinationPort-container .form__label,
    .modal__content.new-address-translation-survey .form-row.boxContainer .services-container .form__label {
      text-align: left;
      margin-left: 12px;
      margin-bottom: -15px; }
    .modal__content.new-address-translation-survey .form-row.boxContainer .sources-container.form__group,
    .modal__content.new-address-translation-survey .form-row.boxContainer .destinations-container.form__group,
    .modal__content.new-address-translation-survey .form-row.boxContainer .destinationPort-container.form__group,
    .modal__content.new-address-translation-survey .form-row.boxContainer .services-container.form__group {
      margin-top: 20px; }
    .modal__content.new-address-translation-survey .form-row.boxContainer .sources-container .form__textinput,
    .modal__content.new-address-translation-survey .form-row.boxContainer .destinations-container .form__textinput,
    .modal__content.new-address-translation-survey .form-row.boxContainer .destinationPort-container .form__textinput,
    .modal__content.new-address-translation-survey .form-row.boxContainer .services-container .form__textinput {
      padding-left: 12px;
      margin-bottom: 0;
      margin-top: 15px;
      height: 40px; }
  .modal__content.new-address-translation-survey .form__textinput {
    font-size: 14px;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 16px;
    background-color: transparent;
    border: 0;
    border-bottom: 1px #ededed solid;
    padding: 4px 8px 4px 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    @media only screen and (max-width: 992px) {
      .modal__content.new-address-translation-survey .form__textinput {
        margin-bottom: 8px; } }
    .modal__content.new-address-translation-survey .form__textinput::placeholder {
      color: #d3d7d8;
      font-size: 14px;
      font-weight: normal; }
    .modal__content.new-address-translation-survey .form__textinput.multiline {
      word-break: break-word; }
    .modal__content.new-address-translation-survey .form__textinput:focus {
      border-bottom: 2px #f68b1e solid;
      outline: 0; }
  @media screen and (max-width: 1275px) {
    .modal__content.create-new-ecosystem .flex-column-container {
      display: flex;
      flex-direction: column; }
      .modal__content.create-new-ecosystem .flex-column-container .flex-column, .modal__content.create-new-ecosystem .flex-column-container .modal__content.new-address-survey .objectinfo, .modal__content.new-address-survey .modal__content.create-new-ecosystem .flex-column-container .objectinfo {
        flex: 1; } }
  @media only screen and (min-width: 992px) {
    .modal__content.create-new-ecosystem .form__group {
      max-width: 75%; }
    .modal__content.create-new-ecosystem .ecosystem--user__new .form__textinput,
    .modal__content.create-new-ecosystem .ecosystem--users_single .form__textinput {
      min-width: 252px; } }
  .modal__content.create-new-ecosystem .ecosystem--users_single {
    justify-content: flex-start;
    align-items: center;
    position: relative; }
    .modal__content.create-new-ecosystem .ecosystem--users_single .newUser__label {
      color: red;
      position: absolute;
      top: -2px;
      font-size: 12px; }
    .modal__content.create-new-ecosystem .ecosystem--users_single .form__textinput {
      width: 50%; }
    .modal__content.create-new-ecosystem .ecosystem--users_single .user__divider {
      padding: 0 12px; }
    .modal__content.create-new-ecosystem .ecosystem--users_single i {
      color: #abb1b2;
      font-size: 20px;
      margin-left: 16px;
      margin-right: 4px;
      cursor: pointer; }
      .modal__content.create-new-ecosystem .ecosystem--users_single i:hover {
        color: #76888f; }
  .modal__content.create-new-ecosystem .ecosystem--user__new {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 8px; }
    .modal__content.create-new-ecosystem .ecosystem--user__new .form__textinput {
      margin-bottom: 0;
      margin-right: 12px; }
  .modal__content.create-new-ecosystem .wedge-checkbox-container {
    margin-left: 32px; }
    .modal__content.create-new-ecosystem .wedge-checkbox-container .checkmark {
      height: 18px;
      width: 18px; }
      .modal__content.create-new-ecosystem .wedge-checkbox-container .checkmark:after {
        top: 3px;
        left: 6px; }
  .modal__content.create-new-ecosystem .nsp-container {
    display: flex; }
  .modal__content.new-gateway-survey .flex-two {
    flex: 2; }
  .modal__content.new-gateway-survey .flex-three {
    flex: 3; }
  .modal__content.new-gateway-survey .unset-maxWidth {
    max-width: unset; }
  .modal__content.new-gateway-survey .ip-form-divider {
    margin-left: 16px;
    margin-right: 16px; }
  @media (min-width: 1368px) {
    .modal__content.new-gateway-survey .card.basic .content .form-row .form__group:nth-child(2) {
      max-width: 40%; } }
  .modal__content.new-gateway-survey .content .divider {
    color: #d0d4d6;
    height: 1px;
    opacity: 1; }
  .modal__content.new-gateway-survey .content .gateway-config {
    display: flex; }
    @media (min-width: 992px) {
      .modal__content.new-gateway-survey .content .gateway-config__ips {
        width: 250px; } }
    @media (min-width: 1368px) {
      .modal__content.new-gateway-survey .content .gateway-config__networks {
        width: 40%; } }
  .modal__content.new-gateway-survey label {
    margin-top: 8px;
    text-transform: none; }
    .modal__content.new-gateway-survey label .check_title {
      font-size: 14px;
      line-height: 1;
      white-space: nowrap; }
      .modal__content.new-gateway-survey label .check_title::after {
        content: '?';
        border: 1px solid #6d8994;
        border-radius: 4px;
        font-size: 9px;
        margin-left: 16px;
        padding: 0 4px; }
    .modal__content.new-gateway-survey label .check_subtitle {
      font-size: 12px;
      line-height: 2; }
  .modal__content.new-gateway-survey .local-networks--container {
    margin-top: 8px;
    background-color: #fdfdfd;
    border: 1px #ececec solid;
    border-radius: 2px;
    padding: 8px 8px 0 8px; }
    .modal__content.new-gateway-survey .local-networks--container .red-delete-icon {
      font-size: 20px;
      color: red;
      font-weight: bold;
      cursor: pointer; }
    .modal__content.new-gateway-survey .local-networks--container table {
      width: 100%; }
    .modal__content.new-gateway-survey .local-networks--container tr {
      border-bottom: 1px #ececec solid;
      height: 50px;
      vertical-align: center; }
      .modal__content.new-gateway-survey .local-networks--container tr.new-entry {
        border-bottom: 0;
        vertical-align: center; }
        .modal__content.new-gateway-survey .local-networks--container tr.new-entry td:first-child {
          padding-right: 12px; }
    .modal__content.new-gateway-survey .local-networks--container th,
    .modal__content.new-gateway-survey .local-networks--container td {
      font-size: 14px;
      font-weight: bold;
      color: #869da7; }
      .modal__content.new-gateway-survey .local-networks--container th.last,
      .modal__content.new-gateway-survey .local-networks--container td.last {
        width: fit-content;
        padding: 4px 8px; }
  .modal__content.new-gateway-survey .gateway__detail {
    display: flex; }
    .modal__content.new-gateway-survey .gateway__detail .flex-one {
      flex: 1 1; }
    .modal__content.new-gateway-survey .gateway__detail-info {
      word-break: break-all; }
    @media only screen and (min-width: 340px) {
      .modal__content.new-gateway-survey .gateway__detail-vpn {
        min-width: 330px; } }
    @media only screen and (min-width: 1200px) {
      .modal__content.new-gateway-survey .gateway__detail-vpn {
        height: fit-content; } }
    .modal__content.new-gateway-survey .gateway__detail-vpn .vpn-collapse {
      margin-left: 24px; }
      .modal__content.new-gateway-survey .gateway__detail-vpn .vpn-collapse .field__group {
        margin-bottom: 12px; }
    .modal__content.new-gateway-survey .gateway__detail-vpn .vpn_title {
      color: #4a0b96;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      width: fit-content; }
    .modal__content.new-gateway-survey .gateway__detail-vpn .psk-info i {
      cursor: pointer; }
    .modal__content.new-gateway-survey .gateway__detail-vpn .psk-info p, .modal__content.new-gateway-survey .gateway__detail-vpn .psk-info__options {
      color: #abb1b2;
      font-size: 14px;
      margin-top: 12px; }
    .modal__content.new-gateway-survey .gateway__detail-vpn .psk-info__options {
      display: flex;
      align-items: center; }
      @media only screen and (max-width: 480px) {
        .modal__content.new-gateway-survey .gateway__detail-vpn .psk-info__options {
          display: block; } }
      .modal__content.new-gateway-survey .gateway__detail-vpn .psk-info__options .psk-data {
        display: flex;
        align-items: center; }
        .modal__content.new-gateway-survey .gateway__detail-vpn .psk-info__options .psk-data .psk-actions {
          display: flex;
          align-items: center; }
        @media only screen and (max-width: 340px) {
          .modal__content.new-gateway-survey .gateway__detail-vpn .psk-info__options .psk-data {
            display: block; }
            .modal__content.new-gateway-survey .gateway__detail-vpn .psk-info__options .psk-data .psk-actions {
              margin-top: 5px; } }
      .modal__content.new-gateway-survey .gateway__detail-vpn .psk-info__options .psk-option {
        display: flex;
        align-items: center; }
        .modal__content.new-gateway-survey .gateway__detail-vpn .psk-info__options .psk-option::before {
          content: '|';
          padding-left: 8px;
          padding-right: 8px; }
      .modal__content.new-gateway-survey .gateway__detail-vpn .psk-info__options .psk-text {
        font-size: 11px;
        background: none;
        border: 0.5px solid #abb1b2;
        border-radius: 3px;
        padding: 6px;
        margin-left: 6px;
        width: 160px; }
        @media only screen and (max-width: 480px) {
          .modal__content.new-gateway-survey .gateway__detail-vpn .psk-info__options .psk-text {
            color: #333333; } }
      .modal__content.new-gateway-survey .gateway__detail-vpn .psk-info__options .psk-show {
        display: flex;
        height: 20px; }
    .modal__content.new-gateway-survey .gateway__detail-internet {
      display: flex; }
      @media only screen and (max-width: 992px) {
        .modal__content.new-gateway-survey .gateway__detail-internet {
          flex-direction: column; } }
    .modal__content.new-gateway-survey .gateway__detail .local-networks--container {
      width: 60%; }
  .modal__content .form-section {
    margin-top: 15px; }
  .modal__content .form-header {
    padding: 0;
    margin: 0 0 20px 0;
    font-size: 18px;
    color: #6d8994; }
  .modal__content .popOverContainer {
    display: flex;
    align-items: center;
    justify-content: unset; }
    .modal__content .popOverContainer h5 {
      font-size: 14px;
      font-weight: 600;
      color: #6d8994;
      margin-bottom: 0; }
    @media screen and (max-width: 992) {
      .modal__content .popOverContainer {
        justify-content: center; } }
    .modal__content .popOverContainer.checkbox-help .help-icon {
      margin-bottom: 5px; }
  .modal__content.new-user-survey {
    padding: 0; }
    .modal__content.new-user-survey h5 {
      color: #6d8994; }
    .modal__content.new-user-survey h3 {
      color: #6d8994;
      font-weight: 500; }
    .modal__content.new-user-survey .small-icon {
      margin-right: 5px; }
  .modal__content.new-user-survey-manual .select-with-create {
    padding: 4px 0 0px 0; }
  .modal__content.new-user-survey-csv {
    color: #6d8994; }
    .modal__content.new-user-survey-csv .custom-header {
      font-size: 14px;
      font-weight: bold; }
    .modal__content.new-user-survey-csv label {
      margin-top: 0px;
      text-transform: none; }
    .modal__content.new-user-survey-csv .headerCheckbox {
      margin-top: 10px; }
  .modal__content.user-invitation .local-users--container, .modal__content.new-user-survey-manual .local-users--container, .modal__content.new-user-survey-csv .local-users--container {
    overflow: scroll; }
    .modal__content.user-invitation .local-users--container .red-delete-icon, .modal__content.new-user-survey-manual .local-users--container .red-delete-icon, .modal__content.new-user-survey-csv .local-users--container .red-delete-icon {
      font-size: 20px;
      color: red;
      font-weight: bold;
      cursor: pointer; }
    .modal__content.user-invitation .local-users--container table, .modal__content.new-user-survey-manual .local-users--container table, .modal__content.new-user-survey-csv .local-users--container table {
      width: 100%;
      min-width: 625px; }
    .modal__content.user-invitation .local-users--container tbody, .modal__content.new-user-survey-manual .local-users--container tbody, .modal__content.new-user-survey-csv .local-users--container tbody {
      display: flex;
      flex-direction: column; }
    .modal__content.user-invitation .local-users--container tr, .modal__content.new-user-survey-manual .local-users--container tr, .modal__content.new-user-survey-csv .local-users--container tr {
      display: flex;
      vertical-align: middle;
      margin-top: 8px;
      padding: 5px 0; }
      .modal__content.user-invitation .local-users--container tr.tHeader, .modal__content.new-user-survey-manual .local-users--container tr.tHeader, .modal__content.new-user-survey-csv .local-users--container tr.tHeader {
        margin-top: 0px;
        border-bottom: 1px #ececec solid;
        margin-bottom: 8px;
        font-weight: bold; }
      .modal__content.user-invitation .local-users--container tr.new-entry, .modal__content.new-user-survey-manual .local-users--container tr.new-entry, .modal__content.new-user-survey-csv .local-users--container tr.new-entry {
        margin-top: 8px;
        border-bottom: 0;
        vertical-align: middle; }
      .modal__content.user-invitation .local-users--container tr.entries, .modal__content.new-user-survey-manual .local-users--container tr.entries, .modal__content.new-user-survey-csv .local-users--container tr.entries {
        display: flex;
        align-items: center; }
        .modal__content.user-invitation .local-users--container tr.entries td, .modal__content.new-user-survey-manual .local-users--container tr.entries td, .modal__content.new-user-survey-csv .local-users--container tr.entries td {
          font-size: 16px; }
        .modal__content.user-invitation .local-users--container tr.entries .fName, .modal__content.new-user-survey-manual .local-users--container tr.entries .fName, .modal__content.new-user-survey-csv .local-users--container tr.entries .fName {
          flex: 1; }
        .modal__content.user-invitation .local-users--container tr.entries .lName, .modal__content.new-user-survey-manual .local-users--container tr.entries .lName, .modal__content.new-user-survey-csv .local-users--container tr.entries .lName {
          flex: 1; }
        .modal__content.user-invitation .local-users--container tr.entries .role, .modal__content.new-user-survey-manual .local-users--container tr.entries .role, .modal__content.new-user-survey-csv .local-users--container tr.entries .role {
          flex: 1.5; }
        .modal__content.user-invitation .local-users--container tr.entries .email, .modal__content.new-user-survey-manual .local-users--container tr.entries .email, .modal__content.new-user-survey-csv .local-users--container tr.entries .email {
          flex: 2; }
        .modal__content.user-invitation .local-users--container tr.entries .pwd, .modal__content.new-user-survey-manual .local-users--container tr.entries .pwd, .modal__content.new-user-survey-csv .local-users--container tr.entries .pwd {
          flex: 1; }
        .modal__content.user-invitation .local-users--container tr.entries .last, .modal__content.new-user-survey-manual .local-users--container tr.entries .last, .modal__content.new-user-survey-csv .local-users--container tr.entries .last {
          flex: 0.25;
          display: flex;
          justify-content: center; }
    .modal__content.user-invitation .local-users--container th,
    .modal__content.user-invitation .local-users--container td, .modal__content.new-user-survey-manual .local-users--container th,
    .modal__content.new-user-survey-manual .local-users--container td, .modal__content.new-user-survey-csv .local-users--container th,
    .modal__content.new-user-survey-csv .local-users--container td {
      font-size: 14px;
      color: #869da7;
      padding-right: 12px;
      overflow-wrap: anywhere; }
      .modal__content.user-invitation .local-users--container th.last,
      .modal__content.user-invitation .local-users--container td.last, .modal__content.new-user-survey-manual .local-users--container th.last,
      .modal__content.new-user-survey-manual .local-users--container td.last, .modal__content.new-user-survey-csv .local-users--container th.last,
      .modal__content.new-user-survey-csv .local-users--container td.last {
        width: fit-content;
        padding: 4px 8px; }
  .modal__content.user-invitation .link {
    text-decoration: underline;
    cursor: pointer;
    color: #4a0b96;
    font-size: 16px; }
  .modal__content.user-invitation .local-users--container {
    margin-top: 10px; }
    .modal__content.user-invitation .local-users--container table thead {
      padding: 0 12px;
      display: block;
      border: 1px solid #6d8994;
      background-color: #e7e7e7; }
    .modal__content.user-invitation .local-users--container table tbody {
      padding: 0 12px;
      max-height: 200px;
      overflow-y: auto;
      display: block;
      border: 1px solid #6d8994;
      border-top: 0; }
    .modal__content.user-invitation .local-users--container tr {
      display: flex;
      vertical-align: middle;
      margin-top: 0; }
      .modal__content.user-invitation .local-users--container tr.tHeader {
        margin-top: 0px;
        border-bottom: 1px #ececec solid;
        margin-bottom: 0;
        font-weight: bold; }
  .modal__content.confirmation .blue.underline {
    color: #4a0b96;
    text-decoration: underline; }
  .modal__content.confirmation .details {
    font-weight: 600; }
  .modal__content.confirmation .card .content {
    padding: 24px; }
    @media screen and (max-width: 991px) {
      .modal__content.confirmation .card .content {
        padding: 16px; } }
    .modal__content.confirmation .card .content h3 {
      margin-top: 0;
      color: #6d8994;
      font-weight: 500; }
  .modal__content.user-invitation, .modal__content.log-settings, .modal__content.ldap-config {
    color: #6d8994;
    padding: 0px;
    margin-bottom: 15px; }
    @media only screen and (min-width: 992px) {
      .modal__content.user-invitation .form__group, .modal__content.log-settings .form__group, .modal__content.ldap-config .form__group {
        max-width: unset; } }
    .modal__content.user-invitation .hidden, .modal__content.log-settings .hidden, .modal__content.ldap-config .hidden {
      display: none; }
    .modal__content.user-invitation .card .content, .modal__content.log-settings .card .content, .modal__content.ldap-config .card .content {
      padding-top: 24px;
      padding-bottom: 24px;
      margin-top: 0px; }
      @media screen and (max-width: 991px) {
        .modal__content.user-invitation .card .content, .modal__content.log-settings .card .content, .modal__content.ldap-config .card .content {
          padding-top: 16px; } }
      .modal__content.user-invitation .card .content h3, .modal__content.log-settings .card .content h3, .modal__content.ldap-config .card .content h3 {
        margin-top: 0; }
    .modal__content.user-invitation label, .modal__content.log-settings label, .modal__content.ldap-config label {
      text-transform: none; }
    .modal__content.user-invitation .sub-header, .modal__content.log-settings .sub-header, .modal__content.ldap-config .sub-header {
      color: #6d8994;
      margin-top: 20px; }
      .modal__content.user-invitation .sub-header .first, .modal__content.log-settings .sub-header .first, .modal__content.ldap-config .sub-header .first {
        margin-top: 15px; }
    .modal__content.user-invitation .header-block, .modal__content.log-settings .header-block, .modal__content.ldap-config .header-block {
      margin-bottom: 5px;
      margin-top: 10px; }
      .modal__content.user-invitation .header-block h5, .modal__content.log-settings .header-block h5, .modal__content.ldap-config .header-block h5 {
        margin-bottom: 0;
        margin-top: 0;
        font-size: 14px;
        font-weight: 600; }
    .modal__content.user-invitation p.cert-trigger, .modal__content.log-settings p.cert-trigger, .modal__content.ldap-config p.cert-trigger {
      display: flex;
      align-items: center;
      font-size: 13px;
      margin-top: 5px;
      cursor: pointer; }
      .modal__content.user-invitation p.cert-trigger .cert-Header, .modal__content.log-settings p.cert-trigger .cert-Header, .modal__content.ldap-config p.cert-trigger .cert-Header {
        font-weight: 400; }
      .modal__content.user-invitation p.cert-trigger .certLink, .modal__content.log-settings p.cert-trigger .certLink, .modal__content.ldap-config p.cert-trigger .certLink {
        display: flex;
        color: #4a0b96;
        margin-left: 4px;
        font-style: underline;
        text-decoration: underline; }
        .modal__content.user-invitation p.cert-trigger .certLink:hover, .modal__content.log-settings p.cert-trigger .certLink:hover, .modal__content.ldap-config p.cert-trigger .certLink:hover {
          color: #00a550; }
    .modal__content.user-invitation .idpOptions,
    .modal__content.user-invitation .form__radioGroup, .modal__content.log-settings .idpOptions,
    .modal__content.log-settings .form__radioGroup, .modal__content.ldap-config .idpOptions,
    .modal__content.ldap-config .form__radioGroup {
      margin-left: -5px; }
      .modal__content.user-invitation .idpOptions .contextHelp,
      .modal__content.user-invitation .form__radioGroup .contextHelp, .modal__content.log-settings .idpOptions .contextHelp,
      .modal__content.log-settings .form__radioGroup .contextHelp, .modal__content.ldap-config .idpOptions .contextHelp,
      .modal__content.ldap-config .form__radioGroup .contextHelp {
        margin-left: 0px; }
    .modal__content.user-invitation .selected-option, .modal__content.log-settings .selected-option, .modal__content.ldap-config .selected-option {
      margin-right: 5px;
      font-size: 15px;
      padding-top: 8px;
      padding-bottom: 8px; }
    .modal__content.user-invitation .credentials, .modal__content.log-settings .credentials, .modal__content.ldap-config .credentials {
      margin-top: 35px; }
    .modal__content.user-invitation .summary .download__options, .modal__content.log-settings .summary .download__options, .modal__content.ldap-config .summary .download__options {
      display: flex;
      align-items: center; }
    .modal__content.user-invitation .summary .desc-header, .modal__content.log-settings .summary .desc-header, .modal__content.ldap-config .summary .desc-header {
      margin-top: 15px; }
    .modal__content.user-invitation .summary i, .modal__content.log-settings .summary i, .modal__content.ldap-config .summary i {
      margin: 0 4px;
      cursor: pointer; }
    .modal__content.user-invitation .ldap-config-description,
    .modal__content.user-invitation .description, .modal__content.log-settings .ldap-config-description,
    .modal__content.log-settings .description, .modal__content.ldap-config .ldap-config-description,
    .modal__content.ldap-config .description {
      color: #6d8994; }
      .modal__content.user-invitation .ldap-config-description .details,
      .modal__content.user-invitation .description .details, .modal__content.log-settings .ldap-config-description .details,
      .modal__content.log-settings .description .details, .modal__content.ldap-config .ldap-config-description .details,
      .modal__content.ldap-config .description .details {
        font-weight: 600; }
    .modal__content.user-invitation .ldap-config-connection .ldap-url-config .header-block h5,
    .modal__content.user-invitation .ldap-config-connection .url-config .header-block h5,
    .modal__content.user-invitation .connection .ldap-url-config .header-block h5,
    .modal__content.user-invitation .connection .url-config .header-block h5, .modal__content.log-settings .ldap-config-connection .ldap-url-config .header-block h5,
    .modal__content.log-settings .ldap-config-connection .url-config .header-block h5,
    .modal__content.log-settings .connection .ldap-url-config .header-block h5,
    .modal__content.log-settings .connection .url-config .header-block h5, .modal__content.ldap-config .ldap-config-connection .ldap-url-config .header-block h5,
    .modal__content.ldap-config .ldap-config-connection .url-config .header-block h5,
    .modal__content.ldap-config .connection .ldap-url-config .header-block h5,
    .modal__content.ldap-config .connection .url-config .header-block h5 {
      margin-bottom: 0px;
      margin-top: 10px; }
    .modal__content.user-invitation .ldap-config-connection .ldap-url-config .ldap-url,
    .modal__content.user-invitation .ldap-config-connection .ldap-url-config .url,
    .modal__content.user-invitation .ldap-config-connection .url-config .ldap-url,
    .modal__content.user-invitation .ldap-config-connection .url-config .url,
    .modal__content.user-invitation .connection .ldap-url-config .ldap-url,
    .modal__content.user-invitation .connection .ldap-url-config .url,
    .modal__content.user-invitation .connection .url-config .ldap-url,
    .modal__content.user-invitation .connection .url-config .url, .modal__content.log-settings .ldap-config-connection .ldap-url-config .ldap-url,
    .modal__content.log-settings .ldap-config-connection .ldap-url-config .url,
    .modal__content.log-settings .ldap-config-connection .url-config .ldap-url,
    .modal__content.log-settings .ldap-config-connection .url-config .url,
    .modal__content.log-settings .connection .ldap-url-config .ldap-url,
    .modal__content.log-settings .connection .ldap-url-config .url,
    .modal__content.log-settings .connection .url-config .ldap-url,
    .modal__content.log-settings .connection .url-config .url, .modal__content.ldap-config .ldap-config-connection .ldap-url-config .ldap-url,
    .modal__content.ldap-config .ldap-config-connection .ldap-url-config .url,
    .modal__content.ldap-config .ldap-config-connection .url-config .ldap-url,
    .modal__content.ldap-config .ldap-config-connection .url-config .url,
    .modal__content.ldap-config .connection .ldap-url-config .ldap-url,
    .modal__content.ldap-config .connection .ldap-url-config .url,
    .modal__content.ldap-config .connection .url-config .ldap-url,
    .modal__content.ldap-config .connection .url-config .url {
      justify-content: unset; }
      .modal__content.user-invitation .ldap-config-connection .ldap-url-config .ldap-url .scheme,
      .modal__content.user-invitation .ldap-config-connection .ldap-url-config .url .scheme,
      .modal__content.user-invitation .ldap-config-connection .url-config .ldap-url .scheme,
      .modal__content.user-invitation .ldap-config-connection .url-config .url .scheme,
      .modal__content.user-invitation .connection .ldap-url-config .ldap-url .scheme,
      .modal__content.user-invitation .connection .ldap-url-config .url .scheme,
      .modal__content.user-invitation .connection .url-config .ldap-url .scheme,
      .modal__content.user-invitation .connection .url-config .url .scheme, .modal__content.log-settings .ldap-config-connection .ldap-url-config .ldap-url .scheme,
      .modal__content.log-settings .ldap-config-connection .ldap-url-config .url .scheme,
      .modal__content.log-settings .ldap-config-connection .url-config .ldap-url .scheme,
      .modal__content.log-settings .ldap-config-connection .url-config .url .scheme,
      .modal__content.log-settings .connection .ldap-url-config .ldap-url .scheme,
      .modal__content.log-settings .connection .ldap-url-config .url .scheme,
      .modal__content.log-settings .connection .url-config .ldap-url .scheme,
      .modal__content.log-settings .connection .url-config .url .scheme, .modal__content.ldap-config .ldap-config-connection .ldap-url-config .ldap-url .scheme,
      .modal__content.ldap-config .ldap-config-connection .ldap-url-config .url .scheme,
      .modal__content.ldap-config .ldap-config-connection .url-config .ldap-url .scheme,
      .modal__content.ldap-config .ldap-config-connection .url-config .url .scheme,
      .modal__content.ldap-config .connection .ldap-url-config .ldap-url .scheme,
      .modal__content.ldap-config .connection .ldap-url-config .url .scheme,
      .modal__content.ldap-config .connection .url-config .ldap-url .scheme,
      .modal__content.ldap-config .connection .url-config .url .scheme {
        max-width: 100px; }
      .modal__content.user-invitation .ldap-config-connection .ldap-url-config .ldap-url .url .url-text,
      .modal__content.user-invitation .ldap-config-connection .ldap-url-config .url .url .url-text,
      .modal__content.user-invitation .ldap-config-connection .url-config .ldap-url .url .url-text,
      .modal__content.user-invitation .ldap-config-connection .url-config .url .url .url-text,
      .modal__content.user-invitation .connection .ldap-url-config .ldap-url .url .url-text,
      .modal__content.user-invitation .connection .ldap-url-config .url .url .url-text,
      .modal__content.user-invitation .connection .url-config .ldap-url .url .url-text,
      .modal__content.user-invitation .connection .url-config .url .url .url-text, .modal__content.log-settings .ldap-config-connection .ldap-url-config .ldap-url .url .url-text,
      .modal__content.log-settings .ldap-config-connection .ldap-url-config .url .url .url-text,
      .modal__content.log-settings .ldap-config-connection .url-config .ldap-url .url .url-text,
      .modal__content.log-settings .ldap-config-connection .url-config .url .url .url-text,
      .modal__content.log-settings .connection .ldap-url-config .ldap-url .url .url-text,
      .modal__content.log-settings .connection .ldap-url-config .url .url .url-text,
      .modal__content.log-settings .connection .url-config .ldap-url .url .url-text,
      .modal__content.log-settings .connection .url-config .url .url .url-text, .modal__content.ldap-config .ldap-config-connection .ldap-url-config .ldap-url .url .url-text,
      .modal__content.ldap-config .ldap-config-connection .ldap-url-config .url .url .url-text,
      .modal__content.ldap-config .ldap-config-connection .url-config .ldap-url .url .url-text,
      .modal__content.ldap-config .ldap-config-connection .url-config .url .url .url-text,
      .modal__content.ldap-config .connection .ldap-url-config .ldap-url .url .url-text,
      .modal__content.ldap-config .connection .ldap-url-config .url .url .url-text,
      .modal__content.ldap-config .connection .url-config .ldap-url .url .url-text,
      .modal__content.ldap-config .connection .url-config .url .url .url-text {
        height: 39px; }
        @media screen and (max-width: 992px) {
          .modal__content.user-invitation .ldap-config-connection .ldap-url-config .ldap-url .url .url-text,
          .modal__content.user-invitation .ldap-config-connection .ldap-url-config .url .url .url-text,
          .modal__content.user-invitation .ldap-config-connection .url-config .ldap-url .url .url-text,
          .modal__content.user-invitation .ldap-config-connection .url-config .url .url .url-text,
          .modal__content.user-invitation .connection .ldap-url-config .ldap-url .url .url-text,
          .modal__content.user-invitation .connection .ldap-url-config .url .url .url-text,
          .modal__content.user-invitation .connection .url-config .ldap-url .url .url-text,
          .modal__content.user-invitation .connection .url-config .url .url .url-text, .modal__content.log-settings .ldap-config-connection .ldap-url-config .ldap-url .url .url-text,
          .modal__content.log-settings .ldap-config-connection .ldap-url-config .url .url .url-text,
          .modal__content.log-settings .ldap-config-connection .url-config .ldap-url .url .url-text,
          .modal__content.log-settings .ldap-config-connection .url-config .url .url .url-text,
          .modal__content.log-settings .connection .ldap-url-config .ldap-url .url .url-text,
          .modal__content.log-settings .connection .ldap-url-config .url .url .url-text,
          .modal__content.log-settings .connection .url-config .ldap-url .url .url-text,
          .modal__content.log-settings .connection .url-config .url .url .url-text, .modal__content.ldap-config .ldap-config-connection .ldap-url-config .ldap-url .url .url-text,
          .modal__content.ldap-config .ldap-config-connection .ldap-url-config .url .url .url-text,
          .modal__content.ldap-config .ldap-config-connection .url-config .ldap-url .url .url-text,
          .modal__content.ldap-config .ldap-config-connection .url-config .url .url .url-text,
          .modal__content.ldap-config .connection .ldap-url-config .ldap-url .url .url-text,
          .modal__content.ldap-config .connection .ldap-url-config .url .url .url-text,
          .modal__content.ldap-config .connection .url-config .ldap-url .url .url-text,
          .modal__content.ldap-config .connection .url-config .url .url .url-text {
            height: auto; } }
    .modal__content.user-invitation .ldap-config-connection .ldap-url-config .startTLS,
    .modal__content.user-invitation .ldap-config-connection .url-config .startTLS,
    .modal__content.user-invitation .connection .ldap-url-config .startTLS,
    .modal__content.user-invitation .connection .url-config .startTLS, .modal__content.log-settings .ldap-config-connection .ldap-url-config .startTLS,
    .modal__content.log-settings .ldap-config-connection .url-config .startTLS,
    .modal__content.log-settings .connection .ldap-url-config .startTLS,
    .modal__content.log-settings .connection .url-config .startTLS, .modal__content.ldap-config .ldap-config-connection .ldap-url-config .startTLS,
    .modal__content.ldap-config .ldap-config-connection .url-config .startTLS,
    .modal__content.ldap-config .connection .ldap-url-config .startTLS,
    .modal__content.ldap-config .connection .url-config .startTLS {
      margin: 0; }
    .modal__content.user-invitation .ldap-config-connection .ldap-url-config .privCert,
    .modal__content.user-invitation .ldap-config-connection .url-config .privCert,
    .modal__content.user-invitation .connection .ldap-url-config .privCert,
    .modal__content.user-invitation .connection .url-config .privCert, .modal__content.log-settings .ldap-config-connection .ldap-url-config .privCert,
    .modal__content.log-settings .ldap-config-connection .url-config .privCert,
    .modal__content.log-settings .connection .ldap-url-config .privCert,
    .modal__content.log-settings .connection .url-config .privCert, .modal__content.ldap-config .ldap-config-connection .ldap-url-config .privCert,
    .modal__content.ldap-config .ldap-config-connection .url-config .privCert,
    .modal__content.ldap-config .connection .ldap-url-config .privCert,
    .modal__content.ldap-config .connection .url-config .privCert {
      display: flex;
      align-items: center; }
    .modal__content.user-invitation .ldap-config-connection .ldap-url-config .content-option,
    .modal__content.user-invitation .ldap-config-connection .url-config .content-option,
    .modal__content.user-invitation .connection .ldap-url-config .content-option,
    .modal__content.user-invitation .connection .url-config .content-option, .modal__content.log-settings .ldap-config-connection .ldap-url-config .content-option,
    .modal__content.log-settings .ldap-config-connection .url-config .content-option,
    .modal__content.log-settings .connection .ldap-url-config .content-option,
    .modal__content.log-settings .connection .url-config .content-option, .modal__content.ldap-config .ldap-config-connection .ldap-url-config .content-option,
    .modal__content.ldap-config .ldap-config-connection .url-config .content-option,
    .modal__content.ldap-config .connection .ldap-url-config .content-option,
    .modal__content.ldap-config .connection .url-config .content-option {
      margin-bottom: 10px; }
    .modal__content.user-invitation .ldap-config-ssl .form__group,
    .modal__content.user-invitation .ssl .form__group, .modal__content.log-settings .ldap-config-ssl .form__group,
    .modal__content.log-settings .ssl .form__group, .modal__content.ldap-config .ldap-config-ssl .form__group,
    .modal__content.ldap-config .ssl .form__group {
      max-width: unset; }
    .modal__content.user-invitation .ldap-config-ssl .privCert,
    .modal__content.user-invitation .ssl .privCert, .modal__content.log-settings .ldap-config-ssl .privCert,
    .modal__content.log-settings .ssl .privCert, .modal__content.ldap-config .ldap-config-ssl .privCert,
    .modal__content.ldap-config .ssl .privCert {
      display: flex; }
    .modal__content.user-invitation .ldap-config-ssl .content-option,
    .modal__content.user-invitation .ssl .content-option, .modal__content.log-settings .ldap-config-ssl .content-option,
    .modal__content.log-settings .ssl .content-option, .modal__content.ldap-config .ldap-config-ssl .content-option,
    .modal__content.ldap-config .ssl .content-option {
      margin-bottom: 10px; }
    .modal__content.user-invitation .__react_component_tooltip.show, .modal__content.log-settings .__react_component_tooltip.show, .modal__content.ldap-config .__react_component_tooltip.show {
      opacity: 0.9;
      margin-top: -12px;
      margin-left: -12px;
      visibility: visible; }
    .modal__content.user-invitation .btn-wrapper, .modal__content.log-settings .btn-wrapper, .modal__content.ldap-config .btn-wrapper {
      width: 100%; }
      .modal__content.user-invitation .btn-wrapper .btn, .modal__content.log-settings .btn-wrapper .btn, .modal__content.ldap-config .btn-wrapper .btn {
        border-top: 1px solid #e6ebf1;
        box-shadow: 0 2px 0 0 #e6ebf1; }
        .modal__content.user-invitation .btn-wrapper .btn:focus, .modal__content.log-settings .btn-wrapper .btn:focus, .modal__content.ldap-config .btn-wrapper .btn:focus {
          outline: none; }
      .modal__content.user-invitation .btn-wrapper .ml5, .modal__content.log-settings .btn-wrapper .ml5, .modal__content.ldap-config .btn-wrapper .ml5 {
        margin-left: 5px; }
      .modal__content.user-invitation .btn-wrapper .btn-container, .modal__content.log-settings .btn-wrapper .btn-container, .modal__content.ldap-config .btn-wrapper .btn-container {
        display: flex; }
        @media only screen and (max-width: 992px) {
          .modal__content.user-invitation .btn-wrapper .btn-container, .modal__content.log-settings .btn-wrapper .btn-container, .modal__content.ldap-config .btn-wrapper .btn-container {
            width: 100%;
            justify-content: space-between; } }
        .modal__content.user-invitation .btn-wrapper .btn-container .btn-group.dropdown, .modal__content.log-settings .btn-wrapper .btn-container .btn-group.dropdown, .modal__content.ldap-config .btn-wrapper .btn-container .btn-group.dropdown {
          display: flex;
          margin-left: 5px; }
        .modal__content.user-invitation .btn-wrapper .btn-container .btn-group .dropdown-menu, .modal__content.log-settings .btn-wrapper .btn-container .btn-group .dropdown-menu, .modal__content.ldap-config .btn-wrapper .btn-container .btn-group .dropdown-menu {
          width: 100%;
          border-radius: 0; }
  .modal__content.new-thing-survey .type {
    margin-top: 8px;
    margin-bottom: 16px; }
  .modal__content.new-thing-survey .thing__details {
    display: flex; }
    @media only screen and (max-width: 992px) {
      .modal__content.new-thing-survey .thing__details {
        flex-direction: column;
        justify-content: center;
        align-items: stretch; } }
    .modal__content.new-thing-survey .thing__details .thing__name-type {
      flex: 1; }
    .modal__content.new-thing-survey .thing__details .thing__options {
      flex: 1; }
      .modal__content.new-thing-survey .thing__details .thing__options .field__group {
        margin-bottom: 36px; }
      .modal__content.new-thing-survey .thing__details .thing__options .thing__contribute span {
        color: #4a0b96; }
  .modal__content.new-address-survey .type--thing_single {
    margin: 8px 0;
    justify-content: space-between; }
    .modal__content.new-address-survey .type--thing_single p {
      overflow: hidden;
      text-overflow: ellipsis; }
  .modal__content.new-address-survey .objectinfo {
    display: flex;
    align-items: flex-start;
    justify-content: center; }
    @media only screen and (max-width: 992px) {
      .modal__content.new-address-survey .objectinfo {
        align-items: flex-end; } }
    .modal__content.new-address-survey .objectinfo__button {
      display: inline-block;
      align-items: center;
      background-color: #f8fdff;
      color: #6d8994;
      border: 0.5px #c5d4e2 solid;
      border-radius: 4px;
      padding: 8px 12px;
      text-align: center;
      cursor: pointer;
      box-shadow: 0px 5px 10px -2px rgba(136, 136, 136, 0.1); }
      .modal__content.new-address-survey .objectinfo__button .small-icon {
        height: 20px;
        width: 20px;
        margin: 0 0 0 8px; }
      .modal__content.new-address-survey .objectinfo__button img {
        margin-left: 8px; }
      .modal__content.new-address-survey .objectinfo__button span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal; }
      .modal__content.new-address-survey .objectinfo__button:hover {
        box-shadow: 0px 5px 10px -2px rgba(136, 136, 136, 0.2); }
    .modal__content.new-address-survey .objectinfo__title {
      color: #6d8994;
      white-space: nowrap;
      margin-top: 16px;
      margin-bottom: 18px;
      max-width: 192px;
      text-align: center;
      text-overflow: ellipsis;
      overflow-x: hidden;
      height: 16px;
      font-size: 16px; }
    .modal__content.new-address-survey .objectinfo__type-container {
      justify-content: center;
      align-items: center;
      margin-bottom: 20px; }
      .modal__content.new-address-survey .objectinfo__type-container img {
        margin-right: 8px; }
  .modal__content.new-address-survey .nsp-container {
    display: flex; }
  .modal__content.new-nats-survey {
    min-width: 1200px; }
    .modal__content.new-nats-survey .full {
      justify-content: flex-start; }
      .modal__content.new-nats-survey .full:last-child {
        width: 100%; }

.newUserAlert,
.action__confirm,
.commit-result {
  padding: 32px 24px;
  font-size: 18px; }

.newUserAlert p:first-child {
  padding-bottom: 12px; }

.newUserAlert li {
  font-size: 14px; }

.commit-result .result-header {
  color: #6d8994;
  font-size: 24px;
  padding-bottom: 24px; }

.commit-result .result-body {
  word-break: break-all; }
  .commit-result .result-body p {
    line-height: 32px; }

.action__confirm .form__textinput {
  margin-bottom: 0; }

.commit-finish .wedge-modal__footer {
  text-align: right; }
  .commit-finish .wedge-modal__footer button {
    width: 72px; }

.survey__footer {
  display: flex;
  justify-content: space-between; }
  .new-policy .survey__footer {
    position: fixed;
    right: 60px;
    bottom: 60px; }
  .survey__footer .btn {
    min-width: 100px; }

.nat__footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between; }
  .new-policy .nat__footer {
    position: fixed;
    right: 60px;
    bottom: 60px; }
  .nat__footer .rightActions button:not(:last-child) {
    margin-right: 10px; }
  .nat__footer .btn {
    min-width: 100px; }

.active-text {
  color: #2632c1; }

#hide-psk {
  display: none !important; }

#popover-psk {
  z-index: 1392; }
  #popover-psk .popover-psk {
    color: #6d8994;
    text-align: center; }
    #popover-psk .popover-psk p {
      font-size: 14px;
      margin-bottom: 0; }
      #popover-psk .popover-psk p:first-child {
        color: #abb1b2; }
    #popover-psk .popover-psk .refresh__buttons {
      display: flex;
      justify-content: space-between;
      padding-top: 8px; }
      #popover-psk .popover-psk .refresh__buttons .btn {
        flex: 0.4;
        font-size: 12px; }

.expiry-input {
  border: none;
  border-bottom: 1px solid #ededed;
  margin-top: 16px;
  padding: 4px 0;
  width: 100%; }

.flex-3 {
  flex: 3; }

.flex-2 {
  flex: 2; }

.react-datepicker {
  font-size: 1em !important; }

.react-datepicker__header {
  padding-top: 0.8em !important; }

.react-datepicker__month {
  margin: 0.4em 1em !important; }

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em !important;
  line-height: 1.9em !important;
  margin: 0.166em !important; }

.react-datepicker__current-month {
  font-size: 1em !important; }

.react-datepicker__navigation {
  top: 1em !important;
  line-height: 1.7em !important;
  border: 0.45em solid transparent !important; }

.react-datepicker__navigation--previous {
  border-right-color: #ccc !important;
  left: 1em !important; }

.react-datepicker__navigation--next {
  border-left-color: #ccc !important;
  right: 1em !important; }

.react-datepicker__close-icon::after {
  padding: 0px !important; }

#object__tabs .nav.nav-tabs {
  border: none;
  padding: 16px 40px 0px; }
  @media only screen and (max-width: 992px) {
    #object__tabs .nav.nav-tabs {
      padding: 16px 16px 0 16px;
      display: flex; } }

#object__tabs .object__tab {
  border: none;
  font-size: 16px; }
  @media only screen and (max-width: 992px) {
    #object__tabs .object__tab {
      flex: 1;
      text-align: center; } }
  #object__tabs .object__tab.lessPadded a {
    padding-left: 8px;
    padding-right: 8px; }
  #object__tabs .object__tab a {
    border: none;
    border-bottom: 2px solid transparent;
    color: #abb1b2;
    font-weight: 600; }
    #object__tabs .object__tab a:hover, #object__tabs .object__tab a:focus {
      background-color: #f7f7f7 !important;
      border-bottom: 2px solid #dfe9ea;
      outline: none; }
  #object__tabs .object__tab.active {
    border: none;
    border-bottom: 2px solid #4a0b96; }
    #object__tabs .object__tab.active a {
      background-color: #f7f7f7 !important;
      border: none;
      color: #6d8994; }

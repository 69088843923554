@import '../../assets/sass/lbd/variables';
@import '../../assets/sass/common.scss';
.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}
.ReactModal__Overlay--after-open {
  overflow-y: auto;
  overflow-x: hidden;
}
.ReactModal__Content--after-open {
  transform: translate(-50%, 0%) !important;
  top: 20px !important;
}
.ReactModal__Content {
  &.ReactModal__Content--after-open {
    @media only screen and (max-width: 768px) {
      width: 80% !important;
    }
    @media only screen and (max-width: 480px) {
      width: 100% !important;
    }
  }
}

.wedge-modal {
  overflow-y: initial;

  &__header {
    width: 100%;
    z-index: 10001;
    background-color: $wedge-primary-color;
    padding: 24px 32px;
    color: white;
    @media only screen and (max-width: 992px) {
      padding: 16px;
    }
  }

  &__content {
    background-color: #f7f7f7;
    overflow: initial;
  }

  &__footer {
    background-color: #efefef;
    border-top: 1px #d7dadb solid;
    padding: 24px 32px;
    @media only screen and (max-width: 992px) {
      padding: 16px;
    }
  }
  .header {
    @extend .flex-row;
    align-items: center;
    justify-content: space-between;
    &.centralHeader {
      .close {
        opacity: 1;
      }
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0px;
    }

    .actions-container {
      display: flex;
      align-items: center;
    }

    .action {
      font-size: 24px;
      font-weight: normal;
      cursor: pointer;
      margin-right: 16px;
    }

    .close {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

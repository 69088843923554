@import '../../assets/sass/lbd/variables.scss';

.latng__input {
  // display: flex;
  // justify-content: space-between;
  // margin-top: 16px;

  input {
    border: 0;
    border-bottom: 1px $wedge-light-gray solid;
    box-shadow: none;
    flex: none;
    // width: 250px;
    margin-bottom: 16px;

    padding: 4px 8px 4px 0;

    &:focus {
      border-bottom: 2px $wedge-primary-orange solid;
      outline: 0;
    }
    &::placeholder {
      color: #d3d7d8;
      font-size: 14px;
      font-weight: normal;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.coordinates__input {
}

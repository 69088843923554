@import './_variables.scss';

@media (min-width: 992px) {
  .navbar-form {
    margin-top: 21px;
    margin-bottom: 21px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .footer:not(.footer-big) {
    nav > ul {
      li:first-child {
        margin-left: 0;
      }
    }
  }

  .card {
    form {
      [class*='col-'] {
        padding: 6px;
      }
      [class*='col-']:first-child {
        padding-left: 15px;
      }
      [class*='col-']:last-child {
        padding-right: 15px;
      }
    }
  }
  .navbar {
    &.navbar-default {
      &.navbar-fixed-top {
        left: auto;
        width: calc(100% - #{$sidebar-width});
        .navbar-brand {
          border: none;
          margin: 0 0 0 2px;
          font-size: 22px;
        }
      }
    }
  }
}

/*          Changes for small display      */

@media (max-width: 1150px) {
  .navbar-nav .navbar-item {
    margin: 1px 0;

    @media (max-width: 1075px) {
      i,
      p {
        display: none;
      }

      img {
        margin-right: 0px;
      }
      a {
        border: none;
      }
      .dropdown-menu {
        left: auto;
        right: 0;
      }
      ul {
        &.nav.navbar-nav.navbar-right {
          li {
            &.navbar-item.last {
              a {
                padding-right: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .navbar-transparent {
    padding-top: 15px;
    background-color: rgba(0, 0, 0, 0.45);
  }

  .navbar-nav .navbar-item {
    .commit-tooltip {
      right: -53px;
    }
  }

  body {
    position: relative;
  }

  .main-panel {
    width: 100%;
    @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    .content {
      margin-top: $logo-container-height_small_screen;
    }
  }
  .navbar .container {
    left: 0;
    width: 100%;
    @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    position: relative;
  }
  .navbar .container-fluid {
    width: 100%;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 0px;
    display: flex;
    align-items: center;
  }

  .navbar-nav > li {
    float: none;
    position: relative;
    display: block;
  }

  .sidebar {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: $sidebar-width;
    right: auto;
    left: 0;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding: 0;

    @include transform-translate-x(-#{$sidebar-width});
    @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    > ul {
      position: relative;
      z-index: 4;
      overflow-y: scroll;
      height: calc(100vh - 61px);
      width: 100%;
    }

    &::before {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: #282828;
      display: block;
      content: '';
      z-index: 1;
    }

    .sidebar-wrapper {
      height: calc(100vh - 130px);
      padding-bottom: 30px;
      .nav.pull-right {
        display: none;
      }
      .dropdown-menu {
        & > li:first-child > a,
        & > li:last-child > a {
          border-radius: 4px;
        }

        & > li > a:hover,
        & > li > a:focus {
          color: white;
        }
      }

      & > .nav > div {
        .nav {
          margin-top: 10px;
          float: none !important;

          & > li > a {
            margin: 0;
            line-height: 30px;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            margin: 5px 15px;
          }
        }

        .notification {
          float: left;
          line-height: 30px;
          margin-right: 8px;
          font-weight: 600;
        }
      }
    }
  }
  .sidebar.wedge {
    .logo {
      height: $logo-container-height_small_screen;
    }
  }

  .nav-open {
    .sidebar {
      @include transform-translate-x(0px);
    }

    .navbar .container {
      left: -#{$sidebar-width};
    }

    .main-panel {
      float: left;
      @include transform-translate-x($sidebar-width);
    }
  }
  .navbar-default {
    padding-left: 0;
    display: flex;
    align-items: center;
    height: $logo-container-height_small_screen;
  }
  .dashboard-navbar {
    .navbar-default {
      height: $logo-container-height_small_screen;
      ul {
        .nav {
          > li {
            > a:hover,
            > a:focus {
              background-color: #999;
            }
          }
        }
        &.nav.navbar-nav.navbar-right {
          li {
            &.navbar-item {
              &.last {
                .nav-profile {
                  .btn-group {
                    button {
                      padding: 4px;
                      p {
                        margin-bottom: 0px;
                      }
                    }
                    p {
                      margin-bottom: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .navbar-header {
      float: none;
      align-items: center;
      display: flex;
      .navbar-brand {
        margin: $navbar-margin-brand;
      }
    }
  }

  .navbar-toggle .icon-bar {
    display: block;
    position: relative;
    background: #fff;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto;
  }

  .navbar-header .navbar-toggle {
    margin: 10px 0px 10px 0;
    width: 40px;
    height: 40px;
  }

  .navbar-toggle .icon-bar {
    outline: 1px solid transparent;
  }

  .navbar-toggle.collapsed .icon-bar:nth-child(2) {
    top: 0px;
    @include bar-animation($topbar-back);
  }
  .navbar-toggle.collapsed .icon-bar:nth-child(3) {
    opacity: 1;
  }
  .navbar-toggle.collapsed .icon-bar:nth-child(4) {
    bottom: 0px;
    @include bar-animation($bottombar-back);
  }

  .nav-open .navbar-toggle .icon-bar:nth-child(2) {
    top: 6px;
    @include bar-animation($topbar-x);
  }
  .nav-open .navbar-toggle .icon-bar:nth-child(3) {
    opacity: 0;
  }
  .nav-open .navbar-toggle .icon-bar:nth-child(4) {
    bottom: 6px;
    @include bar-animation($bottombar-x);
  }

  @include topbar-x-rotation();
  @include topbar-back-rotation();
  @include bottombar-x-rotation();
  @include bottombar-back-rotation();

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .navbar-fixed-top {
    -webkit-backface-visibility: hidden;
  }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    left: $sidebar-width;
    right: auto;
    content: '';
    z-index: 9999;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.35);
    transition: all 370ms ease-in;
  }

  .social-line .btn {
    margin: $margin-bottom;
  }
  .subscribe-line .form-control {
    margin: $margin-bottom;
  }
  .social-line.pull-right {
    float: none;
  }
  .footer nav.pull-left {
    float: none !important;
  }
  .footer:not(.footer-big) nav > ul li {
    float: none;
  }
  .social-area.pull-right {
    float: none !important;
  }
  .form-control + .form-control-feedback {
    margin-top: -8px;
  }
  .navbar-toggle:hover,
  .navbar-toggle:focus {
    background-color: transparent !important;
  }
  .btn.dropdown-toggle {
    margin-bottom: 0;
  }
  .media-post .author {
    width: 20%;
    float: none !important;
    display: block;
    margin: 0 auto 10px;
  }
  .media-post .media-body {
    width: 100%;
  }

  .navbar-collapse.collapse {
    height: 100% !important;
    display: block;
    margin-left: auto;
    padding-right: 15px;
  }
  .navbar-collapse.collapse.in {
    display: block;
  }
  .navbar-header .collapse,
  .navbar-toggle {
    display: block !important;
    float: left;
  }
  .navbar-header {
    float: none;
  }
  .navbar-nav .open .dropdown-menu {
    position: absolute;
    float: left;
    width: auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    margin-top: 5px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
  }
  .navbar-collapse {
    .nav p {
      font-size: $font-size-base;
      margin: 0;
    }

    [class^='pe-7s-'] {
      float: left;
      font-size: 20px;
      margin-right: 10px;
    }
  }
}

//overwrite table responsive for 768px screens

@media (min-width: 992px) {
  .table-full-width {
    margin-left: -15px;
    margin-right: -15px;
  }
  .table-responsive {
    overflow: visible;
  }
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    background-color: #f5f5f5;
    color: #333333;
    opacity: 1;
    text-decoration: none;
  }
}
@media (max-width: 991px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }
}

.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1393;
  cursor: pointer;

  .loader-spinner {
    position: absolute;
    left: calc(50% - 16px);
    top: calc(50% - 16px);
    border: 4px dashed #f3f3f3; /* Light grey */
    border-top: 4px dashed #3498db; /* Blue */
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.select-with-create {
  border-bottom: 1px #ededed solid;
  padding: 4px 0;
  margin-bottom: 16px;
  @media only screen and (max-width: 992px) {
    margin-bottom: 8px;
  }
  .dropdown-menu {
    min-width: 100%;
    border-radius: 4px;
    transition: none !important;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    & > li > a {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    & > li:first-child > a {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    max-height: 140px;
    .address-Icon {
      height: 24px;
      margin-left: 6px;
      margin-right: 12px;
    }
    .gateway-Icon {
      width: 30px;
      height: 18px;
      margin-right: 7px;
    }
    .group-Icon {
      height: 20px;
      margin-left: 5px;
      margin-right: 12px;
    }
    .imgContainer {
      width: 40px;
      text-align: center;
      display: inline-block;
    }
  }
  .dropdown-toggle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    padding-left: 0;

    .input-no-border {
      width: 100%;
      border: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .caret {
      display: block;
      align-self: center;
    }
    p {
      font-size: 14px;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: #fff;
      border: none;
      box-shadow: none;
    }
  }
  &.open .dropdown-toggle {
    background-color: #fff;
    border: none;
    box-shadow: none;
    &:hover,
    &:focus,
    &:active {
      background-color: #fff;
      border: none;
      box-shadow: none;
    }
  }
}

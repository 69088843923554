@import '../../assets/sass/lbd/variables.scss';

.create-group-form {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 2px 4px 2px 24px;
  margin-bottom: 0;

  .group-input-field {
    flex: 1 1 auto;
  }

  .save-button {
    color: #36cb98;
    font-size: 12px;
    margin-left: 12px;
    cursor: pointer;

    &:disabled {
      color: #888;
      cursor: not-allowed;
    }
  }

  .error-text {
    font-size: 10px;
    color: red;
  }

  .form__group-input {
    border: 0;
    border-bottom: 1px #ededed solid;
    color: $wedge-blue-gray;
    margin-top: 8px;
    margin-bottom: 0px;
  }
}

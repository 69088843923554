@import '../../assets/sass/lbd/variables';
@import '../../assets/sass/lbd/variables.scss';
@import '../../assets/sass/common.scss';
@import '../../assets/sass/lbd/mixins/_vendor-prefixes.scss';
@import '../../assets/sass/lbd/mixins';
.profile-wrapper.wrapper {
  background-color: $wedge-white-two;
  height: auto;
  .d-flex {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 4;
  }

  .single-tab-container span {
    font-size: 24px;
  }

  .profile-container {
    padding: 48px 8%;
    min-height: calc(100% - 132px);
    display: flex;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    @media only screen and (max-width: 992px) {
      padding: 48px 3%;
      width: 100%;
    }

    & .profile-form {
      @include respond-to($tablet-portrait) {
        width: 100%;
      }
    }

    & .divider {
      width: 100%;
      height: 1px;
      margin-bottom: 21px;
      background-color: $medium-gray;
    }

    & .button-container {
      display: flex;
      justify-content: flex-end;
    }

    & .button {
      padding: 0 32px;
      height: 48px;
      font-size: 18px;

      &-update,
      &-disable {
        background-color: $wedge-secondary-blue;
        color: white;
        border: none;
        margin-left: 12px;
      }

      &-disable {
        background-color: $danger-color;
      }

      &-cancel {
        background: white;
        color: $wedge-secondary-black;
        border: 1px solid $wedge-primary-gray;
        margin-right: 12px;
      }

      &-change {
        background: white;
        color: $wedge-primary-gray;
        border: 1px solid #ccc;
        margin-right: 12px;

        &-avatar {
          margin: 16px 0;
        }
      }

      &-remove {
        cursor: pointer;
        color: $wedge-secondary-blue;
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1350px) {
    .user-profile-container,
    .organization-profile-container {
      .avatar-container {
        width: 23%;
      }
      .form-container {
        width: 77%;
      }
    }
  }

  .user-profile-container,
  .billing-profile-container,
  .organization-profile-container {
    padding: 6% 0 3% 0;

    & .error-msg {
      color: red;
      margin-bottom: 15px;
    }

    & .avatar-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
      @media (min-width: 768px) {
        padding: 0px;
        align-items: flex-start;
        margin-bottom: 0px;
      }
      .left-align {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
      }
    }

    & .form-container.billing {
      width: 100%;
    }

    & .form-container {
      position: relative;

      & label {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-weight: 600;
        margin-bottom: 18px;

        & .labelForm {
          color: $wedge-secondary-blue;
          font-size: 18px;
          margin-bottom: 8px;
          text-transform: uppercase;
        }

        & .inputField {
          padding: 0 12px;
          height: 48px;
          font-size: 18px;
          font-weight: normal;
          border-radius: 3px;
          border: 1px solid #ccc;
          background: white;
          box-shadow: none;

          &::placeholder {
            color: #c5c8c9;
          }
          &:invalid {
            outline: none;
          }
          &:disabled {
            background: $wedge-light-gray;
            color: $wedge-primary-black;
          }
          & .react-phone-number-input__row {
            height: 100%;
          }

          & .react-phone-number-input__input {
            border: none;
            height: calc(0.93em + 6px);
            box-shadow: none;
          }
        }
      }

      & .title {
        font-size: 24px;
        font-weight: 600;
        color: $wedge-primary-gray;
      }
      & .password-form .row {
        position: relative;
        & .error-msg {
          left: 15px;
        }
      }
      .mfa-form .mfa-setting {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .mfa-label {
          font-size: 20px;
          font-weight: 600;
          color: $wedge-primary-gray;
        }
        .mfa-status {
          font-size: 16px;
          font-weight: 600;
          margin-left: 8px;
          &.enabled {
            color: $wedge-secondary-blue;
          }
          &.disabled {
            color: $danger-color;
          }
        }
      }
      & #user-idle-timeout {
        width: 100%;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 22px;
        padding: 12px;
      }
    }

    & .countrySelect {
      padding: 10px 15px !important;
      background: #fff;
      & .country-label {
        font-size: 18px;
      }

      & .flag-options {
        margin: 13px 31px 0px -15px;
        width: 100%;
        padding: 14px 15px 10px 10px;

        & .filterBox input {
          margin: 0 4% 0% 2%;
          flex: 1 1;
          font-size: 18px;
          color: #6d8994;
          border: 0;
          margin-left: 8px;
        }

        & .flag-option {
          .country-flag {
            display: flex;
            align-items: center;
            width: 100% !important;

            img {
              top: 0px;
              height: auto;
            }
          }

          &.has-label {
            padding: 8px 20px 8px 8px;
            margin: 5px 0px;
            white-space: unset;
          }
        }
      }

      & .selected--flag--option {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        padding: 0px;

        & .country-flag {
          height: auto !important;
          width: 100% !important;
          max-width: 90%;
          position: relative;
          display: block;

          & .country-label {
            font-size: 18px;
          }

          img {
            top: -2px;
            height: auto;
          }
        }

        & .arrow-down {
          float: right;
          margin-top: -2px;
        }
      }
    }

    .dropdown {
      width: 100%;
      margin: 0;
      text-align: left;

      &.open,
      &.open:active {
        background: #fff;
        box-shadow: none;

        button,
        button:active,
        button:focus,
        button:hover {
          box-shadow: none;
          background: #fff;
        }
      }

      .dropdown-menu {
        width: 100%;
        max-height: 240px;
        overflow-x: hidden;

        li {
          font-family: 'OpenSans', sans-serif;
          font-size: 18px;
          line-height: 20px;

          a {
            color: $wedge-blue-gray;
            white-space: normal;
          }
        }
      }

      .inputFieldDropdown {
        max-width: 100%;
        background: #fff;
        text-align: left;
        width: 100%;
        font-family: 'OpenSans', sans-serif;
        font-size: 18px;
        line-height: 20px;
        font-weight: 400;
        color: $wedge-blue-gray;
        box-sizing: border-box;
        padding: 0 15px;
        border: solid 1px #d0d4d6;
        margin: 0;
        border-radius: 2px;
        height: 49px;
        display: block;
        white-space: normal;

        .caret {
          position: absolute;
          top: 50%;
          margin: -3px 0 0 0;
          right: 10px;
        }
      }
    }
  }

  .inputFieldDropdownHolder {
    margin: 0;
  }

  .menu-item_overflow_hidden {
    overflow-x: hidden;
  }

  .StripeElement {
    box-sizing: border-box;
    height: 48px !important;
    padding: 12px 12px !important;
    font-size: 18px;
    font-weight: normal;
    border-radius: 3px;
    border: 1px solid #ccc;

    background: red;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  @media screen and (max-width: 767px) {
    .single-tab-container span {
      font-size: 18px;
    }
  }
}

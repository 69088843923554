@import '../../assets/sass/lbd/variables';

.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  height: 37px;

  &.disabled {
    pointer-events: none;
    .square {
      background-color: #9e9e9e;
    }
  }

  .square {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    background-color: $wedge-primary-color;
    border-radius: 2px;
    color: white;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 16px;
    line-height: 20px;
    transition: all 0.3s;
  }

  .text {
    color: $wedge-blue-gray;
    font-size: 16px;
    margin: 0px 0px 0px 10px !important;
    font-weight: normal;
  }

  .dropdown-menu & {
    .square {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      line-height: 16px;
      // text-align: center;
      // vertical-align: middle;
      // display: flex;
      // align-items: center;
    }
    .text {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.loader {
  border: 4px dashed #f3f3f3; /* Light grey */
  border-top: 4px dashed #612ba3; /* Blue */
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin: 8px;
  animation: spin 2s linear infinite;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@import '../../assets/sass/lbd/variables.scss';
@import '../../assets/sass/lbd/mixins/vendor-prefixes';

.expiry-warning {
  padding: 16px;
  position: fixed;
  top: -150px;
  left: 0;
  width: 100%;
  z-index: 1393;
  background-color: $wedge-primary-orange;
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  @include transition(0.3s, $transition-ease-in);
  color: white;
  font-size: 14px;
  font-weight: bold;

  a {
    color: #007dff;
    font-size: 16px;
    font-weight: bold;
    margin-left: 6px;
    line-height: 14px;
    &:hover,
    &:focus {
      color: #007dff;
    }
  }

  &.visible {
    top: 0;
  }
  &.hidden {
    top: -150px;
  }
}

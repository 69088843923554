@import '../../assets/sass/lbd/variables.scss';
@import '../../assets/sass/lbd/mixins.scss';

.signup-form-page {
  &--content {
    display: flex;
    position: relative;
    flex-direction: column;
    z-index: 4;
    padding-top: 100px;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 992px) {
      padding-top: 32px;
      margin: 0 24px;
    }

    &.info-form {
      padding-top: 48px;
    }
    .sign-up-form {
      width: 400px;

      @include respond-to($phone) {
        width: 100%;
      }

      & .signup-title {
        color: $wedge-primary-blue;
        font-weight: $font-weight-semi;
        font-size: 26px;
        margin: 0 0 30px 0;
      }
      &.orgInfo-form {
        input {
          flex: none;
          margin-left: 0px;
        }
      }
      &.orgInfo-form,
      &.payment-form {
        width: 100%;
        max-width: 730px;
        @include respond-to($tablet-portrait) {
          width: 90%;
        }
        @include respond-to($phone) {
          width: 100%;
        }
      }
      & .form-title-container {
        display: flex;
        justify-content: flex-start;
      }

      .form-container {
        width: 100%;
        align-items: center;
        flex-direction: column;
        margin: 0;
        padding: 0;
      }

      .input-container {
        margin-bottom: 18px;
        padding: 16px;
        background-color: white;
        border: 1px solid #d0d4d6;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        & .react-phone-number-input__icon--international {
          /* The international icon size is square */
          /* and also has no border hence the `2 * 1px` `width` and `height` compensation */
          width: calc(0.93em + 6px);
          height: calc(0.93em + 6px);
        }
        & .react-phone-number-input__row {
          width: 100%;
        }

        & .react-phone-number-input__input {
          margin-left: 0;
          height: calc(0.93em + 6px);
          box-shadow: none;
        }
      }

      .wedge-checkbox-container {
        input:checked ~ .checkmark {
          border: none;
          background-color: $wedge-primary-blue;
        }
        .checkmark {
          border: 1px solid $wedge-blue-gray;
          border-radius: 3px;

          &::after {
            left: 8px;
            top: 4px;
          }
        }
        .check_title {
          color: #fff;
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          font-size: $font-size-base;
          font-weight: $font-weight-normal;

          &_policy {
            display: flex;
            color: $wedge-primary-blue;
            margin-left: 4px;
            font-style: underline;
            text-decoration: underline;
            &:hover {
              color: $wedge-primary-blue;
            }
          }
          & i {
            color: $wedge-primary-blue;
            font-size: 28px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
          }
        }
      }

      .error-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0;
        padding-top: 0;
        .close-icon {
          width: 12px;
          height: 12px;
          color: '#AC4847';
        }
      }

      .spinner {
        margin: 0 auto;
      }

      input {
        width: 100%;
        flex: 1;
        font-size: 18px;
        color: $wedge-blue-gray;
        border: 0;
        margin-left: 16px;
        outline: none;
        box-shadow: none;
        @include respond-to($tablet-portrait) {
          margin-left: 8px;
        }
        &::placeholder {
          color: #c5c8c9;
        }
      }
      .icon-container {
        display: inline-block !important;
        align-items: center;
        justify-content: center;
        .input-icon {
          max-height: 18px;
          max-width: 18px;
        }
        .person-icon {
          max-height: 25px;
          max-width: 25px;
        }
        .icon-organization {
          font-size: 18px;
        }
      }
      .signup-button {
        cursor: pointer;
        color: white;
        padding: 16px;
        background-color: $wedge-primary-blue;
        border: none;
        border-radius: 3.8px;
        display: flex;
        justify-content: center;
        padding: 16px;
        font-size: 18px;
        text-align: center;
        margin: 0;
        width: 100%;

        &:disabled {
          background-color: #a581cf;
        }
      }
      .alert-danger {
        background-color: #f2dede;
        border: 1px solid #a94442;
        color: #a94442;
        padding: 16px;
        border-radius: 0.25rem;
        margin-bottom: 24px;
      }
    }
  }

  .signup-button {
    cursor: pointer;
    color: white;
    padding: 16px;
    background-color: $wedge-primary-blue;
    border-radius: 3.8px;
    font-size: 18px;
    text-align: center;
    margin: 0;
    width: 100%;
    height: 50px;
    flex: 0;
  }

  &--footer {
    z-index: 1;
    position: fixed;
    bottom: 0;
    .footer--image {
      background-color: #f9f9f9;
      width: 100%;
      object-fit: contain;

      @media only screen and (max-width: 992px) {
        width: unset;
        max-height: 50vh;
      }
    }
  }
}
.submit-button-container {
  width: 100%;
  height: 50px;
}

@import '../../assets/sass/lbd/variables.scss';
@import '../../assets/sass/lbd/mixins.scss';
@import '../../assets/sass/common.scss';
@import '../../assets/sass/lbd/mixins/_vendor-prefixes.scss';

.notFound-wrapper.wrapper {
  position: relative;
  background-color: #f9f9f9;
  min-height: 100vh;

  .content {
    display: flex;
    position: relative;
    z-index: 4;
    justify-content: center;

    @media only screen and (max-width: 992px) {
      padding-top: 50px;
    }

    .login-form {
      width: 70%;
      max-width: 70%;
      padding: 50px;
      margin: 100px auto;
      background: white;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-flow: row;
      justify-content: space-evenly;
      align-items: center;
      border: 1px solid #d0d4d6;

      @media only screen and (max-width: 992px) {
        margin: 0 24px;
        flex-wrap: wrap;
      }

      p {
        font-size: 14px;
        margin-top: 15px;
      }

      .small {
        font-size: 12px;
      }

      .Grid-cell {
        flex: 1 1 400px;
        max-width: 400px;
      }

      .left {
        width: 317px;
        height: 226px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        text-align: center;
      }

      .right {
        align-items: flex-start;
        justify-content: center;
        vertical-align: middle;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 992px) {
          text-align: center;
          justify-content: center;
        }

        .group {
          padding: 20px 0px 20px 20px;
          @media only screen and (max-width: 992px) {
            padding: 20px 20px 20px 20px;
            text-align: center;
            justify-content: center;
          }
        }

        .title {
          color: $wedge-primary-blue;
          font-weight: bold;
          font-size: 76px;
          margin: 0;
        }

        .header {
          color: $wedge-blue-gray;
          font-weight: 600;
          font-size: 20px;
          margin-top: 15px;
          line-height: 1.4;
        }

        .subHeader {
          color: $wedge-blue-gray;
          font-family: 'ProximaNova';
          font-size: 16px;
          font-weight: normal;
          line-height: 1.25;
          margin-top: 20px;
        }

        a {
          color: $wedge-primary-blue;
        }
      }

      .nav-link {
        display: flex;
        align-items: center;

        &.link {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-radius: 0;
          margin-top: 25px;
          cursor: pointer;
          @media only screen and (max-width: 992px) {
            text-align: center;
            justify-content: center;
          }

          p {
            font-size: 16px;
            font-weight: 600;
            color: $wedge-primary-blue;

            &.link--text {
              margin: 0px 0px 0px 8px;
            }
          }
        }

        .back-arrow {
          width: 7px;
          height: 13px;
        }
      }
    }
  }
}

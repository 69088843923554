@import '../../assets/sass/common.scss';
@import '../../assets/sass/lbd/variables.scss';
@import '../../assets/sass/lbd/mixins/vendor-prefixes';
@import '../../assets/sass/lbd/mixins';

.form {
  &__group {
    @extend .flex-column;
    justify-content: flex-start;

    &-center {
      @extend .flex-column;
      justify-content: center;
    }
    &.horizontal {
      align-items: center;
      .form__label {
        text-align: right;
        @include respond-to($tablet-portrait) {
          text-align: left;
        }
      }
    }
    &.full {
      width: 100%;
    }
    &.self {
      max-width: fit-content !important;
    }
    &.paddingBottom {
      padding-bottom: 16px;
    }
    &.limitWidth {
      max-width: 200px;
    }
  }
  &__label {
    font-size: 14px;
    font-weight: 600;
    color: $wedge-blue-gray;
    margin-bottom: 0;

    .asterisk {
      margin-left: 4px;
      color: #ec2238;
    }
  }
  &__input {
    font-size: 14px;
  }
  &__textinput {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 16px;
    background-color: transparent;
    border: 0;
    border-bottom: 1px #ededed solid;
    padding: 4px 8px 4px 0;
    @include transition(0.3s, $transition-ease);

    &::placeholder {
      color: #d3d7d8;
      font-size: 14px;
      font-weight: normal;
    }
    &:-ms-input-placeholder {
      color: #d3d7d8 !important;
      font-size: 14px;
      font-weight: normal;
    }
    &.multiline {
      word-break: break-word;
    }
    &:focus {
      border-bottom: 1px $wedge-primary-orange solid;
      outline: 0;
    }

    @media only screen and (max-width: 992px) {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  &__select {
    flex: 1;
    margin-bottom: 16px;
    background-color: transparent;
    border: 0;
    border-bottom: 1px #ededed solid;
    padding: 4px 0;
  }

  &__toggle {
    min-width: 75px;
    padding: 8px 16px;

    &.select_deny {
      border-color: $danger-color;
      background-color: $danger-color;
    }

    &:disabled {
      background-color: #e9e9e9;
      &:hover {
        background-color: #e9e9e9;
      }
    }
    &:focus {
      outline: none;
      &:active {
        outline: none;
      }
    }

    @media only screen and (max-width: 992px) {
      margin-bottom: 8px;
      min-width: 75px;
    }
  }
  &__radioButton {
    &.disabled {
      opacity: 0.5;
    }
  }
}

.password-input {
  display: flex;
  align-items: center;
  .password {
    cursor: pointer;
    font-size: 15px;
    margin: 8px 10px 16px 10px;
  }
}

button.btn.btn-primary {
  background-color: #4a0b96;
  border-color: #4a0b96;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.ecosystems-uuid-error {
  background-color: #ff7272;
  color: white;
  padding: 1em;
}

.hide-component {
  display: none !important;
}

@import '../../assets/sass/lbd/variables.scss';

.email-panel-container {
  width: 100%;
  height: 60px;
  display: flex;
  color: #fff;
  align-items: center;
  padding: 10px 20px;
  font: 400 16px/20px 'OpenSans', sans-serif;
  letter-spacing: 0.05em;
}

.email-icon-container {
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: #fff;
}

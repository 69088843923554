@import '../../assets/sass/lbd/variables';
@import '../../assets/sass/lbd/mixins';

.ecosystem-refresh {
  display: flex;
  flex-direction: row-reverse;
  width: 80%;
  margin: 0 auto;
  margin-top: 24px;
}

.ecosystems {
  width: 80%;
  max-width: 80%;
  margin: 32px auto;

  @include respond-to($tablet-portrait) {
    width: 90%;
    max-width: 90%;
  }

  @include respond-to($phone) {
    width: 95%;
    max-width: 95%;
  }

  &-error-container {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }
  &::after {
    content: '';
    max-height: 1px;
    width: 764px;
  }
  .item {
    margin: 0.5rem;
    overflow: hidden;
  }
}

@media screen and (min-width: 40em) {
  .ecosystems {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    flex: 0 1 calc(50% - 1em);
  }
}

@media screen and (min-width: 92em) {
  .item {
    flex: 0 1 calc(33% - 1em);
  }
}

.refresh-button {
  position: fixed;
  bottom: 8px;
  right: 8px;
}

@import '../../assets/sass/lbd/variables.scss';

.help-content p {
  font-size: 14px;
  strong {
    word-break: break-all;
  }
}

.help-icon {
  background: no-repeat;
  border: 1px solid $wedge-secondary-black;
  border-radius: 3px;
  font-size: 10px;
  line-height: 12px;
  margin: 0 4px;
  padding: 0 4px;
  &:focus {
    outline: none;
  }
}

@import '../../assets/sass/lbd/variables.scss';

.panel-success-container {
  margin: 0;
  padding: 10px 20px;
  background-color: $success-green-background;
  color: $success-green-text;
  border-radius: 5px;
  border: 1px solid $success-green-border;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: 400 16px/28px 'Roboto', sans-serif;
  letter-spacing: -0.01em;
}

//Utilities

@import 'mixins/transparency';
@import 'mixins/vendor-prefixes';

//Components

@import 'mixins/buttons';
@import 'mixins/inputs';
@import 'mixins/labels';
@import 'mixins/tabs';

@import 'mixins/navbars';
@import 'mixins/icons';
@import 'mixins/social-buttons';

@import 'mixins/morphing-buttons';

@import 'mixins/cards';

@import 'mixins/chartist';

$phone: '(max-width: 480px)';
$tablet-portrait: '(max-width: 767px)';
$tablet-landscape: '(min-width: 768px) and (max-width: 979px)';
$large-desktop: '(min-width: 1200px)';
$non-retina: 'screen and (-webkit-max-device-pixel-ratio: 1)';
$retina: '(min--moz-device-pixel-ratio: 1.5),
                    (-o-min-device-pixel-ratio: 3/2),
                    (-webkit-min-device-pixel-ratio: 1.5),
                    (min-device-pixel-ratio: 1.5),
                    (min-resolution: 144dpi),
                    (min-resolution: 1.5dppx)';

@mixin respond-to($media) {
  @media only screen and #{$media} {
    @content;
  }
}

@import '../../../../assets/sass/lbd/variables.scss';
@import '../../../../assets/sass/common.scss';

.pending-ecosystem-item {
  @extend .animate-flicker;
  background-color: white;
  border: 1px #dcdfd2 solid;
  border-radius: 3.8px;
  flex: 1;
  margin: 16px;
  min-width: 300px;
  max-width: 400px;
  cursor: pointer;
  box-shadow: 0px 5px 10px -2px rgba(136, 136, 136, 0.1);

  &:hover {
    box-shadow: 0px 5px 10px -2px rgba(136, 136, 136, 0.3);
  }
}

@import '../../assets/sass/lbd/variables.scss';
@import '../../assets/sass/lbd/mixins.scss';

.login-form-page {
  &--content {
    display: flex;
    position: relative;
    z-index: 4;
    padding-top: 100px;
    justify-content: center;

    @media only screen and (max-width: 992px) {
      padding-top: 32px;
      margin: 0 24px;
    }

    .title {
      color: $wedge-primary-blue;
      font-size: 26px;
      font-weight: $font-weight-semi;
      margin: 0 0 30px 0;
      &.reset-pwd {
        margin-bottom: 15px;
      }
    }
    .login-form {
      width: 400px;

      @include respond-to($phone) {
        width: 100%;
      }

      p.mfa-label {
        margin-bottom: 6px;
      }

      .input-container {
        margin-bottom: 18px;
        padding: 16px;
        background-color: white;
        border: 1px solid #d0d4d6;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      input {
        width: 100%;
        flex: 1;
        font-size: 18px;
        color: $wedge-blue-gray;
        border: 0;
        margin-left: 16px;
        outline: none;
        box-shadow: none;
        @include respond-to($tablet-portrait) {
          margin-left: 8px;
        }
        &::placeholder {
          color: #c5c8c9;
        }
      }
      .icon-container {
        display: inline-block !important;
        align-items: center;
        justify-content: center;
        .input-icon {
          max-height: 18px;
          max-width: 18px;
        }
        .person-icon {
          max-height: 25px;
          max-width: 25px;
        }
        .icon-organization {
          font-size: 18px;
        }
      }
      .login-button {
        background-color: $wedge-primary-blue;
        border: none;
        border-radius: 3.8px;
        cursor: pointer;
        color: white;
        display: flex;
        justify-content: center;
        font-size: 18px;
        padding: 16px;
        text-align: center;
        margin: 0;
        width: 100%;
      }
      p,
      span.title {
        font-size: 14px;
        color: $wedge-blue-gray;
        margin: 0;
        line-height: 25px;
      }
      .login-rememberme-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        .wedge-checkbox-container {
          display: flex;
          align-items: center;
          margin-bottom: 0;
        }
      }
      .login-links {
        display: flex;
        justify-content: space-between;
      }
      .alert {
        padding: 16px;
        border-radius: 0.25rem;
        margin-bottom: 24px;

        &-danger {
          background-color: #f2dede;
          border: 1px solid #a94442;
          color: #a94442;
        }
        &-warning {
          background-color: #faebcc;
          border: 1px solid #ffbc67;
          color: #8a6d3b;
        }
      }
      span.sign {
        color: $wedge-primary-blue;
        font-size: 18px;
        font-weight: bold;
        line-height: 60px;
        margin: 6px;
      }
    }
  }

  &--footer {
    z-index: 1;
    position: fixed;
    bottom: 0;
    .footer--image {
      background-color: #f9f9f9;
      width: 100%;
      object-fit: contain;

      @media only screen and (max-width: 992px) {
        width: unset;
        max-height: 50vh;
      }
    }
  }
}

.ecosystemCard {
  box-shadow: 0px 1px 4px #00000029;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  height: fit-content;

  p.label {
    font-size: 16px;
    font-weight: normal;
    white-space: normal;
    padding: 0;
    &.error {
      color: #ff4a55;
      font-size: 12px;
    }
  }
  &__header {
    background: transparent linear-gradient(75deg, #770dce 0%, #42117e 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    padding: 24px 16px 16px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    &.disabled {
      background: transparent linear-gradient(75deg, #9c9c9c 0%, #505050 100%) 0% 0% no-repeat padding-box;
    }
    .ecosystem-name {
      position: relative;

      .name {
        border-bottom: 2px solid rgba(255, 255, 255, 0.38);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;

        input {
          background-color: transparent;
          border: none;
          width: 100%;
        }
        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        input,
        span {
          font-size: 26px;
          line-height: 32px;
          padding-right: 12px;
        }
        img {
          // Hide for now
          display: none;
          cursor: pointer;
        }
      }
      .ecosystem-options {
        position: absolute;
        right: -12px;
        top: 12px;
        img {
          height: 32px;
        }
        .eco-options__toggle {
          background: none;
          box-shadow: none;
          border: none;
          &:active {
            box-shadow: none;
          }
        }
        & .open {
          .eco-options__toggle {
            box-shadow: none;
            background: none;
            &:hover,
            &:focus {
              box-shadow: none;
              background: none;
            }
          }
          .dropdown-menu li.disabled a {
            color: #999;
          }
        }
      }
    }
    .ecosystem-date {
      font-size: 20px;
      line-height: 24px;
      padding-top: 16px;
    }
  }
  &__body {
    background-color: #fff;
    padding: 16px;
    .ecosystem-nsps {
      p {
        color: #707070;
        font-size: 16px;
        padding-bottom: 4px;
      }
      .regions {
        display: flex;
        margin-bottom: 24px;
        div {
          border: 1px solid #612ba3;
          border-radius: 12px;
          color: #707070;
          font-size: 16px;
          margin-right: 10px;
          text-align: center;
          padding: 0px 8px;
          padding-top: 2px;
          min-width: 60px;
        }
      }
    }
    .nsp-map {
      padding: 8px 0;
      svg {
        width: 100%;
        height: auto;
        rect,
        text {
          cursor: pointer;
        }
      }
    }
    .selected-region {
      color: #707070;
      font-size: 16px;
      padding: 8px 0;
      p {
        color: #707070;
        font-size: 14px;
        line-height: 12px;
        margin-bottom: 8px;
      }
      .regions {
        display: flex;
        div {
          border: 1px solid #612ba3;
          border-radius: 12px;
          color: #707070;
          cursor: pointer;
          font-size: 10px;
          line-height: 12px;
          margin-right: 10px;
          text-align: center;
          padding: 2px 6px;
          // &::after {
          //   content: '\2715';
          //   background-color: #25c8ea;
          //   border-radius: 50%;
          //   color: #fff;
          //   height: 12px;
          //   padding: 0 1px;
          //   width: 12px;
          //   text-align: center;
          //   margin-left: 6px;
          // }
        }
      }
    }
    .bandwidth {
      color: #707070;
      &__label {
        font-size: 16px;
        line-height: 18px;
        .bandwidth_value {
          color: #612ba3;
          font-size: 16px;
          line-height: 18px;
        }
      }
      .slider-container {
        margin: 0 auto;
        padding: 36px 0 12px;
        width: 75%;
      }
      .best-match {
        width: 85%;
        margin: 0 auto;
        font-size: 16px;
        padding-top: 6px;
      }
      .note {
        font-size: 16px;
        text-align: center;
        margin-top: 24px;
      }
      .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 20px;
        border-radius: 10px;
        background: #d3d3d3;
        outline: none;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
        margin-bottom: 12px;
      }
      .slider:hover {
        opacity: 1;
      }
      .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 32px;
        height: 20px;
        border-radius: 10px;
        background: #612ba3;
        cursor: pointer;
      }
      .slider::-moz-range-thumb {
        width: 32px;
        height: 20px;
        border-radius: 10px;
        background: #612ba3;
        cursor: pointer;
      }
    }
  }
  &__footer {
    background-color: #fff;
    border-top: 1px dashed #c4c4c4;
    padding: 16px 16px 24px;
    display: flex;
    justify-content: flex-end;
    &.deleted {
      justify-content: flex-start;
    }

    button.btn {
      border-radius: 20px;
      font-size: 20px;
      margin-left: 12px;
      padding: 4px;
      width: 120px;
      &:disabled {
        background-color: #666;
        border-color: #d3d3d3;
      }
      &:focus,
      &:active:focus {
        outline: none;
      }
    }
  }
}

/*      light colors         */
.signUp-registration .form-container-stripe {
  max-width: 726px;
  padding: 50px 0 0 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto; }
  .signUp-registration .form-container-stripe .col {
    margin: 0 0 22px; }
  .signUp-registration .form-container-stripe .container {
    width: 100%; }
  .signUp-registration .form-container-stripe label {
    display: block; }
  .signUp-registration .form-container-stripe .labelForm {
    text-transform: uppercase;
    font-family: 'OpenSans', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    letter-spacing: 0;
    display: block;
    margin: 0 0 13px;
    font-weight: 600; }
  .signUp-registration .form-container-stripe input[type='submit'] {
    display: block;
    width: 100%;
    height: 50px;
    margin: 0;
    border-radius: 3px;
    color: #fff;
    font-family: 'OpenSans', sans-serif;
    font-size: 18px;
    line-height: 20px;
    background: #612ba3; }
  .signUp-registration .form-container-stripe input.inputField,
  .signUp-registration .form-container-stripe .inputField {
    background: #fff;
    width: 100%;
    font-family: 'OpenSans', sans-serif;
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    color: #6d8994;
    box-sizing: border-box;
    padding: 0 15px;
    margin: 0;
    height: 50px;
    display: block;
    border-radius: 3px;
    border: solid 1px #d0d4d6; }
    .signUp-registration .form-container-stripe input.inputField::-webkit-input-placeholder,
    .signUp-registration .form-container-stripe .inputField::-webkit-input-placeholder {
      color: #c5c8c9;
      opacity: 0.9;
      font-style: normal; }
    .signUp-registration .form-container-stripe input.inputField:-moz-placeholder,
    .signUp-registration .form-container-stripe .inputField:-moz-placeholder {
      color: #c5c8c9;
      opacity: 0.9;
      font-style: normal; }
    .signUp-registration .form-container-stripe input.inputField::-moz-placeholder,
    .signUp-registration .form-container-stripe .inputField::-moz-placeholder {
      color: #c5c8c9;
      opacity: 0.9;
      font-style: normal; }
    .signUp-registration .form-container-stripe input.inputField:-ms-input-placeholder,
    .signUp-registration .form-container-stripe .inputField:-ms-input-placeholder {
      color: #c5c8c9;
      opacity: 0.9;
      font-style: normal; }
  .signUp-registration .form-container-stripe .inputFieldDropdownHolder {
    margin: 0; }
  .signUp-registration .form-container-stripe .dropdown {
    width: 100%;
    margin: 0;
    text-align: left; }
    .signUp-registration .form-container-stripe .dropdown.open, .signUp-registration .form-container-stripe .dropdown.open:active {
      background: #fff;
      box-shadow: none; }
      .signUp-registration .form-container-stripe .dropdown.open button,
      .signUp-registration .form-container-stripe .dropdown.open button:active,
      .signUp-registration .form-container-stripe .dropdown.open button:focus,
      .signUp-registration .form-container-stripe .dropdown.open button:hover, .signUp-registration .form-container-stripe .dropdown.open:active button,
      .signUp-registration .form-container-stripe .dropdown.open:active button:active,
      .signUp-registration .form-container-stripe .dropdown.open:active button:focus,
      .signUp-registration .form-container-stripe .dropdown.open:active button:hover {
        box-shadow: none;
        background: #fff; }
    .signUp-registration .form-container-stripe .dropdown .dropdown-menu {
      width: 100%;
      max-height: 240px;
      overflow-x: hidden; }
      .signUp-registration .form-container-stripe .dropdown .dropdown-menu li {
        font-family: 'OpenSans', sans-serif;
        font-size: 18px;
        line-height: 20px; }
        .signUp-registration .form-container-stripe .dropdown .dropdown-menu li a {
          color: #6d8994;
          white-space: normal; }
    .signUp-registration .form-container-stripe .dropdown .inputFieldDropdown {
      max-width: 100%;
      background: #fff;
      text-align: left;
      width: 100%;
      font-family: 'OpenSans', sans-serif;
      font-size: 18px;
      line-height: 20px;
      font-weight: 400;
      color: #6d8994;
      box-sizing: border-box;
      padding: 0 15px;
      border: solid 1px #d0d4d6;
      margin: 0;
      border-radius: 2px;
      height: 49px;
      display: block;
      white-space: normal; }
      .signUp-registration .form-container-stripe .dropdown .inputFieldDropdown .caret {
        position: absolute;
        top: 50%;
        margin: -3px 0 0 0;
        right: 10px; }
  .signUp-registration .form-container-stripe .inputField {
    padding: 14px 15px; }
  .signUp-registration .form-container-stripe .submit-container {
    margin-bottom: 100px; }
  .signUp-registration .form-container-stripe .countrySelect .country-label {
    font-size: 18px; }
  .signUp-registration .form-container-stripe .countrySelect .flag-options {
    margin: 13px 31px 0px -15px;
    width: 100%;
    padding: 14px 15px 10px 15px; }
    .signUp-registration .form-container-stripe .countrySelect .flag-options .filterBox input {
      margin: 0 4% 0% 2%; }
    .signUp-registration .form-container-stripe .countrySelect .flag-options .flag-option .country-flag {
      display: flex;
      align-items: center;
      width: 100% !important; }
      .signUp-registration .form-container-stripe .countrySelect .flag-options .flag-option .country-flag img {
        top: 0px;
        height: auto; }
    .signUp-registration .form-container-stripe .countrySelect .flag-options .flag-option.has-label {
      padding: 8px 20px 8px 8px;
      margin: 5px 0px;
      white-space: unset; }
  .signUp-registration .form-container-stripe .countrySelect .selected--flag--option {
    font-size: 16px;
    display: flex;
    justify-content: space-between; }
    .signUp-registration .form-container-stripe .countrySelect .selected--flag--option .country-flag {
      height: auto !important;
      width: 100% !important;
      max-width: 90%;
      position: relative;
      display: block; }
      .signUp-registration .form-container-stripe .countrySelect .selected--flag--option .country-flag .country-label {
        font-size: 18px; }
      .signUp-registration .form-container-stripe .countrySelect .selected--flag--option .country-flag img {
        top: -2px;
        height: auto; }
    .signUp-registration .form-container-stripe .countrySelect .selected--flag--option .arrow-down {
      float: right;
      margin-top: -2px; }

.signUp-registration .StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  background: red;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease; }

.signUp-registration .menu-item_overflow_hidden {
  overflow-x: hidden; }

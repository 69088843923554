@font-face {
  font-family: 'Pe-icon-7-stroke';
  src: url('../fonts/Pe-icon-7-stroke.eot?d7yf1v');
  src: url('../fonts/Pe-icon-7-stroke.eot?#iefixd7yf1v')
      format('embedded-opentype'),
    url('../fonts/Pe-icon-7-stroke.woff?d7yf1v') format('woff'),
    url('../fonts/Pe-icon-7-stroke.ttf?d7yf1v') format('truetype'),
    url('../fonts/Pe-icon-7-stroke.svg?d7yf1v#Pe-icon-7-stroke') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='pe-7s-'],
[class*=' pe-7s-'] {
  display: inline-block;
  font-family: 'Pe-icon-7-stroke';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pe-7s-album:before {
  content: '\e6aa';
}
.pe-7s-arc:before {
  content: '\e6ab';
}
.pe-7s-back-2:before {
  content: '\e6ac';
}
.pe-7s-bandaid:before {
  content: '\e6ad';
}
.pe-7s-car:before {
  content: '\e6ae';
}
.pe-7s-diamond:before {
  content: '\e6af';
}
.pe-7s-door-lock:before {
  content: '\e6b0';
}
.pe-7s-eyedropper:before {
  content: '\e6b1';
}
.pe-7s-female:before {
  content: '\e6b2';
}
.pe-7s-gym:before {
  content: '\e6b3';
}
.pe-7s-hammer:before {
  content: '\e6b4';
}
.pe-7s-headphones:before {
  content: '\e6b5';
}
.pe-7s-helm:before {
  content: '\e6b6';
}
.pe-7s-hourglass:before {
  content: '\e6b7';
}
.pe-7s-leaf:before {
  content: '\e6b8';
}
.pe-7s-magic-wand:before {
  content: '\e6b9';
}
.pe-7s-male:before {
  content: '\e6ba';
}
.pe-7s-map-2:before {
  content: '\e6bb';
}
.pe-7s-next-2:before {
  content: '\e6bc';
}
.pe-7s-paint-bucket:before {
  content: '\e6bd';
}
.pe-7s-pendrive:before {
  content: '\e6be';
}
.pe-7s-photo:before {
  content: '\e6bf';
}
.pe-7s-piggy:before {
  content: '\e6c0';
}
.pe-7s-plugin:before {
  content: '\e6c1';
}
.pe-7s-refresh-2:before {
  content: '\e6c2';
}
.pe-7s-rocket:before {
  content: '\e6c3';
}
.pe-7s-settings:before {
  content: '\e6c4';
}
.pe-7s-shield:before {
  content: '\e6c5';
}
.pe-7s-smile:before {
  content: '\e6c6';
}
.pe-7s-usb:before {
  content: '\e6c7';
}
.pe-7s-vector:before {
  content: '\e6c8';
}
.pe-7s-wine:before {
  content: '\e6c9';
}
.pe-7s-cloud-upload:before {
  content: '\e68a';
}
.pe-7s-cash:before {
  content: '\e68c';
}
.pe-7s-close:before {
  content: '\e680';
}
.pe-7s-bluetooth:before {
  content: '\e68d';
}
.pe-7s-cloud-download:before {
  content: '\e68b';
}
.pe-7s-way:before {
  content: '\e68e';
}
.pe-7s-close-circle:before {
  content: '\e681';
}
.pe-7s-id:before {
  content: '\e68f';
}
.pe-7s-angle-up:before {
  content: '\e682';
}
.pe-7s-wristwatch:before {
  content: '\e690';
}
.pe-7s-angle-up-circle:before {
  content: '\e683';
}
.pe-7s-world:before {
  content: '\e691';
}
.pe-7s-angle-right:before {
  content: '\e684';
}
.pe-7s-volume:before {
  content: '\e692';
}
.pe-7s-angle-right-circle:before {
  content: '\e685';
}
.pe-7s-users:before {
  content: '\e693';
}
.pe-7s-angle-left:before {
  content: '\e686';
}
.pe-7s-user-female:before {
  content: '\e694';
}
.pe-7s-angle-left-circle:before {
  content: '\e687';
}
.pe-7s-up-arrow:before {
  content: '\e695';
}
.pe-7s-angle-down:before {
  content: '\e688';
}
.pe-7s-switch:before {
  content: '\e696';
}
.pe-7s-angle-down-circle:before {
  content: '\e689';
}
.pe-7s-scissors:before {
  content: '\e697';
}
.pe-7s-wallet:before {
  content: '\e600';
}
.pe-7s-safe:before {
  content: '\e698';
}
.pe-7s-volume2:before {
  content: '\e601';
}
.pe-7s-volume1:before {
  content: '\e602';
}
.pe-7s-voicemail:before {
  content: '\e603';
}
.pe-7s-video:before {
  content: '\e604';
}
.pe-7s-user:before {
  content: '\e605';
}
.pe-7s-upload:before {
  content: '\e606';
}
.pe-7s-unlock:before {
  content: '\e607';
}
.pe-7s-umbrella:before {
  content: '\e608';
}
.pe-7s-trash:before {
  content: '\e609';
}
.pe-7s-tools:before {
  content: '\e60a';
}
.pe-7s-timer:before {
  content: '\e60b';
}
.pe-7s-ticket:before {
  content: '\e60c';
}
.pe-7s-target:before {
  content: '\e60d';
}
.pe-7s-sun:before {
  content: '\e60e';
}
.pe-7s-study:before {
  content: '\e60f';
}
.pe-7s-stopwatch:before {
  content: '\e610';
}
.pe-7s-star:before {
  content: '\e611';
}
.pe-7s-speaker:before {
  content: '\e612';
}
.pe-7s-signal:before {
  content: '\e613';
}
.pe-7s-shuffle:before {
  content: '\e614';
}
.pe-7s-shopbag:before {
  content: '\e615';
}
.pe-7s-share:before {
  content: '\e616';
}
.pe-7s-server:before {
  content: '\e617';
}
.pe-7s-search:before {
  content: '\e618';
}
.pe-7s-film:before {
  content: '\e6a5';
}
.pe-7s-science:before {
  content: '\e619';
}
.pe-7s-disk:before {
  content: '\e6a6';
}
.pe-7s-ribbon:before {
  content: '\e61a';
}
.pe-7s-repeat:before {
  content: '\e61b';
}
.pe-7s-refresh:before {
  content: '\e61c';
}
.pe-7s-add-user:before {
  content: '\e6a9';
}
.pe-7s-refresh-cloud:before {
  content: '\e61d';
}
.pe-7s-paperclip:before {
  content: '\e69c';
}
.pe-7s-radio:before {
  content: '\e61e';
}
.pe-7s-note2:before {
  content: '\e69d';
}
.pe-7s-print:before {
  content: '\e61f';
}
.pe-7s-network:before {
  content: '\e69e';
}
.pe-7s-prev:before {
  content: '\e620';
}
.pe-7s-mute:before {
  content: '\e69f';
}
.pe-7s-power:before {
  content: '\e621';
}
.pe-7s-medal:before {
  content: '\e6a0';
}
.pe-7s-portfolio:before {
  content: '\e622';
}
.pe-7s-like2:before {
  content: '\e6a1';
}
.pe-7s-plus:before {
  content: '\e623';
}
.pe-7s-left-arrow:before {
  content: '\e6a2';
}
.pe-7s-play:before {
  content: '\e624';
}
.pe-7s-key:before {
  content: '\e6a3';
}
.pe-7s-plane:before {
  content: '\e625';
}
.pe-7s-joy:before {
  content: '\e6a4';
}
.pe-7s-photo-gallery:before {
  content: '\e626';
}
.pe-7s-pin:before {
  content: '\e69b';
}
.pe-7s-phone:before {
  content: '\e627';
}
.pe-7s-plug:before {
  content: '\e69a';
}
.pe-7s-pen:before {
  content: '\e628';
}
.pe-7s-right-arrow:before {
  content: '\e699';
}
.pe-7s-paper-plane:before {
  content: '\e629';
}
.pe-7s-delete-user:before {
  content: '\e6a7';
}
.pe-7s-paint:before {
  content: '\e62a';
}
.pe-7s-bottom-arrow:before {
  content: '\e6a8';
}
.pe-7s-notebook:before {
  content: '\e62b';
}
.pe-7s-note:before {
  content: '\e62c';
}
.pe-7s-next:before {
  content: '\e62d';
}
.pe-7s-news-paper:before {
  content: '\e62e';
}
.pe-7s-musiclist:before {
  content: '\e62f';
}
.pe-7s-music:before {
  content: '\e630';
}
.pe-7s-mouse:before {
  content: '\e631';
}
.pe-7s-more:before {
  content: '\e632';
}
.pe-7s-moon:before {
  content: '\e633';
}
.pe-7s-monitor:before {
  content: '\e634';
}
.pe-7s-micro:before {
  content: '\e635';
}
.pe-7s-menu:before {
  content: '\e636';
}
.pe-7s-map:before {
  content: '\e637';
}
.pe-7s-map-marker:before {
  content: '\e638';
}
.pe-7s-mail:before {
  content: '\e639';
}
.pe-7s-mail-open:before {
  content: '\e63a';
}
.pe-7s-mail-open-file:before {
  content: '\e63b';
}
.pe-7s-magnet:before {
  content: '\e63c';
}
.pe-7s-loop:before {
  content: '\e63d';
}
.pe-7s-look:before {
  content: '\e63e';
}
.pe-7s-lock:before {
  content: '\e63f';
}
.pe-7s-lintern:before {
  content: '\e640';
}
.pe-7s-link:before {
  content: '\e641';
}
.pe-7s-like:before {
  content: '\e642';
}
.pe-7s-light:before {
  content: '\e643';
}
.pe-7s-less:before {
  content: '\e644';
}
.pe-7s-keypad:before {
  content: '\e645';
}
.pe-7s-junk:before {
  content: '\e646';
}
.pe-7s-info:before {
  content: '\e647';
}
.pe-7s-home:before {
  content: '\e648';
}
.pe-7s-help2:before {
  content: '\e649';
}
.pe-7s-help1:before {
  content: '\e64a';
}
.pe-7s-graph3:before {
  content: '\e64b';
}
.pe-7s-graph2:before {
  content: '\e64c';
}
.pe-7s-graph1:before {
  content: '\e64d';
}
.pe-7s-graph:before {
  content: '\e64e';
}
.pe-7s-global:before {
  content: '\e64f';
}
.pe-7s-gleam:before {
  content: '\e650';
}
.pe-7s-glasses:before {
  content: '\e651';
}
.pe-7s-gift:before {
  content: '\e652';
}
.pe-7s-folder:before {
  content: '\e653';
}
.pe-7s-flag:before {
  content: '\e654';
}
.pe-7s-filter:before {
  content: '\e655';
}
.pe-7s-file:before {
  content: '\e656';
}
.pe-7s-expand1:before {
  content: '\e657';
}
.pe-7s-exapnd2:before {
  content: '\e658';
}
.pe-7s-edit:before {
  content: '\e659';
}
.pe-7s-drop:before {
  content: '\e65a';
}
.pe-7s-drawer:before {
  content: '\e65b';
}
.pe-7s-download:before {
  content: '\e65c';
}
.pe-7s-display2:before {
  content: '\e65d';
}
.pe-7s-display1:before {
  content: '\e65e';
}
.pe-7s-diskette:before {
  content: '\e65f';
}
.pe-7s-date:before {
  content: '\e660';
}
.pe-7s-cup:before {
  content: '\e661';
}
.pe-7s-culture:before {
  content: '\e662';
}
.pe-7s-crop:before {
  content: '\e663';
}
.pe-7s-credit:before {
  content: '\e664';
}
.pe-7s-copy-file:before {
  content: '\e665';
}
.pe-7s-config:before {
  content: '\e666';
}
.pe-7s-compass:before {
  content: '\e667';
}
.pe-7s-comment:before {
  content: '\e668';
}
.pe-7s-coffee:before {
  content: '\e669';
}
.pe-7s-cloud:before {
  content: '\e66a';
}
.pe-7s-clock:before {
  content: '\e66b';
}
.pe-7s-check:before {
  content: '\e66c';
}
.pe-7s-chat:before {
  content: '\e66d';
}
.pe-7s-cart:before {
  content: '\e66e';
}
.pe-7s-camera:before {
  content: '\e66f';
}
.pe-7s-call:before {
  content: '\e670';
}
.pe-7s-calculator:before {
  content: '\e671';
}
.pe-7s-browser:before {
  content: '\e672';
}
.pe-7s-box2:before {
  content: '\e673';
}
.pe-7s-box1:before {
  content: '\e674';
}
.pe-7s-bookmarks:before {
  content: '\e675';
}
.pe-7s-bicycle:before {
  content: '\e676';
}
.pe-7s-bell:before {
  content: '\e677';
}
.pe-7s-battery:before {
  content: '\e678';
}
.pe-7s-ball:before {
  content: '\e679';
}
.pe-7s-back:before {
  content: '\e67a';
}
.pe-7s-attention:before {
  content: '\e67b';
}
.pe-7s-anchor:before {
  content: '\e67c';
}
.pe-7s-albums:before {
  content: '\e67d';
}
.pe-7s-alarm:before {
  content: '\e67e';
}
.pe-7s-airplay:before {
  content: '\e67f';
}

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?o2nwij');
  src: url('../fonts/icomoon.eot?o2nwij#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?o2nwij') format('truetype'),
    url('../fonts/icomoon.woff?o2nwij') format('woff'),
    url('../fonts/icomoon.svg?o2nwij#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-acreto-mobile-icon:before {
  content: '\e906';
}
.icon-organization:before {
  content: '\e907';
}
.icon-card:before {
  content: '\e900';
}
.icon-check:before {
  content: '\e901';
}
.icon-cross:before {
  content: '\e902';
}
.icon-email-address:before {
  content: '\e903';
}
.icon-paypal:before {
  content: '\e904';
}
.icon-suer:before {
  content: '\e905';
}

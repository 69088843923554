.global-loader {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &--logo {
    width: 24%;
    @media screen and (max-width: 1023px) {
      width: 48%;
    }
    @media screen and (max-width: 767px) {
      width: 60%;
    }
  }
}

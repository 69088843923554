.modal__content.padded.deactive-2fa {
  img {
    display: block;
    margin: 0 auto;
  }
  .description {
    padding: 12px 0;
    p {
      font-size: 18px;
      margin-bottom: 6px;
    }
  }
  .code-input {
    display: flex;
    justify-content: center;
    user-select: none;

    input {
      width: 40px !important;
      text-align: center;
      font-weight: bold;
      color: #8aaaff;
      font-size: 40px;
      border: none;
      border-radius: 0;
      border-bottom: solid 2px #c3d5ff;
      margin-right: 10px;
      padding: 0 !important;
      background: transparent !important;
      -moz-appearance: textfield;

      &:last-child {
        margin-right: 0;
      }
      &::-moz-selection {
        background: transparent;
      }
      &::selection {
        background: transparent;
      }
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    padding: 0 24px;
    button {
      width: 100%;
    }
  }
}

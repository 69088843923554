@import '../../assets/sass/lbd/variables.scss';
@import '../../assets/sass/lbd/mixins.scss';

.tabs-header-container {
  height: 48px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid $wedge-secondary-gray;

  @include respond-to($tablet-portrait) {
    justify-content: center;
  }

  .single-tab-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'OpenSans', sans-serif;
    font-size: 18px;
    line-height: 20px;
    padding: 0 12px;
    margin-right: 60px;
    color: $wedge-blue-gray;

    @include respond-to($tablet-portrait) {
      flex: 1;
      margin-right: 0;
    }
    &.--active {
      color: $wedge-primary-blue;
      border-bottom: 1px solid $wedge-primary-blue;
    }
    .icon-container {
      margin: 0 5px;
    }
  }
}

.--active {
  &:before {
    color: $wedge-primary-blue;
  }
}

.error-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  padding-top: 0;
}

.cross-icon-container {
  font-size: 20px;
  color: '#AC4847';
}
